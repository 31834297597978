<template>
  <div class="loan-dashboard">
    <vsud-loader v-if="loader.dashboard.general || loader.dashboard.pensioners"></vsud-loader>
    <div class="processed-data" v-else>
      <!-- Row 1 : Phase & Progress bar -->
      <div class="row mb-4">
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="pb-0 card-header">
                  <h6>Year / Phase</h6>
                </div>
                <div class="card-body pb-5">
                  <div class="input-group pb-2">
                      <div class="input-group-prepend">
                          <div 
                              class="input-group-text" 
                              style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                              &nbsp; <i class="fa fa-calendar"></i>
                          </div>
                      </div>
                      <select name="report-type" class="form-control" id="inlineFormInputGroup">
                          <option value="2024"> &nbsp; 2024 ( Phase 1 ) </option>                                           
                      </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="card">
            <div class="card-header pb-0">
              <h6>No. of pensioners verified</h6>
            </div>
            <div class="card-body">
              <div class="progress-bar-holder">
                <div class="top-percent p-1">
                    <b>{{ progress_bar.percent.toFixed(2) }}  %</b>
                </div>
                <div class="mid-progress-bar">
                  <vsud-progress
                    color="success"
                    class="mx-auto"
                    variant="gradient"
                    barType="normal"
                    :percentage="parseFloat(progress_bar.percent.toFixed(2))"
                  />
                </div>
                <div class="bottom-data" style="text-align:right; font-size:17px">
                  <b> 
                    <span class="text-success">{{progress_bar.verified.toLocaleString("en-us")}}</span> 
                    <span> of {{progress_bar.target.toLocaleString("en-us")}}</span> </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Row 2 : Pensioner & Verification Activity -->
      <div class="row mb-4">
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Pensioners</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body small">
              <div class="p-1 mb-3" style="height:120px;">
                <table class="table table-bordered">
                  <tr>
                    <td class="text-secondary p-1">
                      <i class="fas fa-globe" aria-hidden="true"></i> Target
                    </td>
                    <td :style="styleSmallTable()">{{ progress_bar.target.toLocaleString("en-us") }}</td>
                    <td :style="styleSmallTable()">100 %</td>
                  </tr>
                  <tr>
                    <td class="text-info p-1">
                      <i class="ni ni-money-coins" aria-hidden="true"></i> Verified
                    </td>
                    <td :style="styleSmallTable()">{{progress_bar.verified.toLocaleString("en-us")}}</td>
                    <td :style="styleSmallTable()">{{ progress_bar.percent.toFixed(2) }} %</td>
                  </tr>
                  <tr>
                    <td class="text-success p-1">
                      <i class="ni ni-money-coins" aria-hidden="true"></i> Not-Verified
                    </td>
                    <td :style="styleSmallTable()">{{ (parseInt(progress_bar.target) - parseInt(progress_bar.verified)).toLocaleString("en-us") }}</td>
                    <td :style="styleSmallTable()">
                      {{ handlePercentage({ num : parseInt(progress_bar.target) - parseInt(progress_bar.verified), denum : progress_bar.target }).toFixed(2) }} %
                    </td>
                  </tr>
                  <tr></tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Verification Activity</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
           <div class="card-body small">
            <div class="p-1 mb-3" style="height:120px;">
            <table class="table table-bordered">
              <tr>
                <td class="text-info p-1" width="130"><i class="fas fa-calendar"></i> {{ fullMonth() }}</td><td :style="styleSmallTable()" >{{ pensioners.activity.month.toLocaleString("en-us") }}</td>
              </tr>
              <tr>
                <td class="text-success p-1"><i class="fas fa-calendar"></i> This Week</td><td :style="styleSmallTable()" >{{ pensioners.activity.week.toLocaleString("en-us") }}</td>
              </tr>
              <tr>
                <td class="text-warning p-1"><i class="fas fa-calendar"></i> Today </td><td :style="styleSmallTable()" >{{ pensioners.activity.today.toLocaleString("en-us") }}</td>
              </tr>
            </table>
            </div>
          </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Center Verification Activity</h6>
                  <p class="text-sm">
                    Top 3
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li>
                        Filter :
                      </li>
                      <li style="border-top : 1px dashed #CCC" class="pt-2 mt-2">
                      <router-link
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="State Enumeration Activity"
                        target="_blank"
                        :to="{name:'Pension activity by center'}">
                        <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                      </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="p-1 mb-3" style="height:120px;">
                <vsud-spinner v-if="loader.dashboard.center||loader.dashboard.pensioners"></vsud-spinner>
                <table class="table table-bordered" v-else>
                    <tr v-for="(center, index) in pensioners.center.top3" :key="index">
                      <td :style="'color : '+Helper.GetdynamicColors()" :title="center.location_lga">
                        <i class="fas fa-map-marker-alt"></i> {{ truncate(center.location_lga, 15) }}
                      </td>
                      <td :style="'border-left:1px solid #EEE; padding-left:9px'"> 
                        {{(center.total).toLocaleString("en-US")}}
                      </td>
                      <td :style="'border-left:1px solid #EEE; padding-left:9px'">
                        {{ handlePercentage({ num : center.total , denum : progress_bar.target }).toFixed(2)  }} %
                      </td>
                    </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Row 3 : Pensioner verification by zone & Recently Verified Pensioners -->
      <div class="row mb-4">
        <div class="col-lg-8 col-md-8">
          <div class="card">
            <div class="pb-0 card-header">
              <h6>Verification By Center</h6>
              <p class="text-sm">
                ...
              </p>
            </div>
            <div class="card-body" style="height:450px;">
              <div class="text-center p-5 mb-3" > 
                <p v-if="loader.dashboard.center">
                  Loading ...
                </p>
                <div v-else>
                  <bar-column chartYaxisTitle="Pensioners" :chartData="pensioners.center.chart" v-if="pensioners.center.all.length>0"></bar-column>
                  <p v-else>
                    <br/><br/><br/>
                    No data yet
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Recently Verified</h6>
                  <p class="text-sm">
                    . . .
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li>
                      <router-link
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="State Enumeration Activity"
                        target="_blank"
                        :to="{name:'Pensioners'}">
                        &nbsp; <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                      </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" style="height:450px; overflow:auto">
              <div class=" text-center p-5 mb-3" v-if="loader.dashboard.recent_verification">
                <p>
                  <br/><br/><br/>
                  Loading ...
                </p>
              </div>
              <div v-else>
                <RecentlyVerified :listData="pensioners.recent"></RecentlyVerified>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Row 4 : Pension Amount by LGA on Retirement & Pension Amount by Bank -->
      <div class="row mb-4">
        <div class="col-lg-4 col-md-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Pension Stats By Bank</h6>
                  <p class="text-sm">
                    Filter : {{ pensioners.bank.title }} - Top 5
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                      <li style="cursor:pointer" @click="getBankStats('amount')">By Amount</li>
                      <li style="cursor:pointer" @click="getBankStats('count')">By Pensioners count</li>
                      <li style="border-top : 1px dashed #CCC" class="pt-2 mt-2">
                      <router-link
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="State Enumeration Activity"
                        target="_blank"
                        :to="{name:'Pension Amount By Bank'}">
                        <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                      </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" style="height:420px">
              <div class=" text-center mb-3">
                <p class="p-5" v-if="loader.dashboard.bank">
                  <br/><br/><br/><br/>
                  Loading ...
                </p>
                <pie v-else :chartData="pensioners.bank.data"></pie>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-8">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Pension Stats By LGA On Retirement</h6>
                  <p class="text-sm">
                    By {{ pensioners.retirement.lga.filter }}
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                      <li style="cursor:pointer" @click="getLgaOnRetirment('verified')">By Verified</li>
                      <li style="cursor:pointer" @click="getLgaOnRetirment('not-verified')">By Not-verified</li>
                      <li style="border-top : 1px dashed #CCC" class="pt-2 mt-2">
                      <router-link
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="State Enumeration Activity"
                        target="_blank"
                        :to="{name:'LGA On Retirement'}">
                        <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                      </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" style="height:420px">
              <div class="text-center p-5 mb-3" > 
                <p v-if="loader.dashboard.retirement.lga">
                  <br/><br/><br/><br/>
                  Loading ...
                </p>
                <div v-else>
                  <bar-column chartYaxisTitle="Pensioners" :chartData="pensioners.retirement.lga.chart" v-if="pensioners.retirement.lga.all.length>0"></bar-column>
                  <p v-else>
                    <br/><br/><br/><br/>
                    No data yet
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Row 4 : Pension Amount by MDA on Retirement  -->
      <div class="row mb-4">
        <div class="col-lg-12 col-md-12">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Pension Stats By MDA On Retirement</h6>
                  <p class="text-sm">
                    By {{ pensioners.retirement.mda.filter }}
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                      <li style="cursor:pointer" @click="getMdaOnRetirment('verified')">By Verified</li>
                      <li style="cursor:pointer" @click="getMdaOnRetirment('not-verified')">By Not-verified</li>
                      <li style="border-top : 1px dashed #CCC" class="pt-2 mt-2">
                      <router-link
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="State Enumeration Activity"
                        target="_blank"
                        :to="{name:'MDA On Retirement'}">
                        <i class="fa fa-eye"></i>  &nbsp;&nbsp;&nbsp; View All
                      </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" style="height:420px">
              <div class="text-center p-5 mb-3" > 
                <p v-if="loader.dashboard.retirement.mda">
                  <br/><br/><br/><br/>
                  Loading ...
                </p>
                <div v-else>
                  <bar-column chartYaxisTitle="Pensioners" :chartData="pensioners.retirement.mda.chart" v-if="pensioners.retirement.mda.all.length>0"></bar-column>
                  <p v-else>
                    <br/><br/><br/><br/>
                    No data yet
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            
      <!-- Row 6 : Verification exercise Pie -->
      <div class="row mb-4">
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Pension Amount</h6>
                  <p class="text-sm">
                    State / LGA
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" style="height:420px">
                <p class="text-center" v-if="loader.dashboard.pension">
                  <br/><br/><br/><br/><br/>
                  Loading ...
                </p>
                <div v-else>
                  <pie :chartData="pensioners.amount.chart" v-if="pensioners.amount.all!==null"></pie>
                  <p v-else>
                    <br/><br/><br/><br/><br/>
                    No data yet
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Gender</h6>
                  <p class="text-sm">
                    . . .
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" style="height:420px">
                <p class="text-center" v-if="loader.dashboard.gender">
                  <br/><br/><br/><br/><br/>
                  Loading ...
                </p>
                <div v-else>
                  <pie :chartData="pensioners.gender.chart" v-if="pensioners.gender.all!==null"></pie>
                  <p v-else>
                    <br/><br/><br/><br/><br/>
                    No data yet
                  </p>
                </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card">
            <div class="pb-0 card-header">
              <div class="row">
                <div class="col-lg-9 col-7">
                  <h6>Overall excersice</h6>
                  <p class="text-sm">
                    State / LGA : {{ pensioners.overall.type }}
                  </p>
                </div>
                <div class="col-lg-3 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li><b><i class="fas fa-filter"></i> Filter :</b></li>
                      <template 
                        v-if="pensioners.overall.state.data!==null&&pensioners.overall.state.data!==null">
                        <li 
                          @click="getStateStats('verified')">
                          Verified
                        </li>
                        <li 
                          @click="getStateStats('not-verified')">
                          Not-Verified
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" style="height:420px">
              <p class="text-center" v-if="loader.dashboard.overall">
                <br/><br/><br/><br/><br/>
                Loading ...
              </p>
              <div v-else>
                <pie 
                  :chartData="pensioners.overall.chart" 
                  v-if="pensioners.overall.state.data!==null&&pensioners.overall.lga.data!==null">
                </pie>
                <p class="text-center" v-else>
                  <br/><br/><br/><br/><br/>
                  No data yet
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import AuthService from '@/services/auth.service.js';
import VsudProgress from "@/components/VsudProgress.vue";
import VsudLoader from "@/components/VsudLoader.vue";
import Helper from  "@/helpers/generalHandlers.js";
import Pie from '@/views/components/dashboard/charts/appexchart/PieDonut.vue';
import BarColumn from '@/views/components/dashboard/charts/appexchart/BarColumn.vue';
import BarStacked from '@/views/components/dashboard/charts/appexchart/BarStacked.vue';
import SemiCircleGuage from '@/views/components/dashboard/charts/appexchart/SemiCircleGuageChart.vue';
import MonoChrome from '@/views/components/dashboard/charts/appexchart/PolarAreaChart.vue';
import SemiCircleGuageChart from '../components/dashboard/charts/appexchart/SemiCircleGuageChart.vue';
import RecentlyVerified from "@/views/components/dashboard/UpdatedBeneficiaries/Layout.vue";

export default {
  name: "dashboard-verification",
  data()
  {
    let def = '--';
    
    return {
      /**
       * New Data Bind
       * 
       * @description : N/A 
       */
       timer : null,
       Helper,
       byPhase : '2024',
       progress_bar : {
        target : 26855,
        total : 0,
        verified : 0,
        percent : 0
       },
       pensioners : {
         stats : {
          total : def,
          verified : def,
          not_verified : def
         },
         activity : {
          month : def,
          week : def,
          today : def
         },
         top_states : [],
         recent : [],
         center : {
          all   : [],
          top3  : [],
          chart : {}
         },
         retirement : {
          lga : {
            all : [],
            chart : {},
            filter : ''
          },
          mda : {
            all : [],
            chart : {},
            filter : ''
          }
         },
         amount : {
            all : null,
            chart : {}
         },
         gender : {
            all : null,
            chart : {}
         },
         bank : {
          title : 'By amount',
          data : {}
         },
         overall : {
          type : null,
          state : {
            data : null,
            verified : 0,
            not_verified : 0,
          },
          lga : {
            data : null,
            verified : 0,
            not_verified : 0,           
          },
          chart : {}
         }
       },
       loader : {  
       dashboard : 
        {
            general : false,
            recent_verification : false,
            pensioners : false,
            bank : false,
            center : false,
            retirement : { 
              lga : false,
              mda : false
            },
            pension : false,
            gender : false,
            overall : false
        },
        others : {}
       },
    };
  },

  components : { 
    VsudSpinner,
    VsudProgress, 
    VsudLoader, 
    Pie, 
    BarColumn,
    BarStacked,
    SemiCircleGuage,
    MonoChrome,
    SemiCircleGuageChart,
    RecentlyVerified
  },
  
  created()
  {
    this.RunDashboard();

    this.timer = setInterval(() => { this.RunDashboard() }, 300000);
  },
  
  methods : {
    /**
     * New API Sync 
     * 
     * @description : N/A 
     */
    RunDashboard()
    {
      this.getGeneralStats();
      this.getRecentVerifications();
      this.getPensionerStats();
      this.getBankStats();
      this.getActivityByCenter();
      this.getLgaOnRetirment();
      this.getMdaOnRetirment();
      this.getPensionAmount();
      this.getGenderData();
      this.getStateStats();
    },

    async getGeneralStats()
    {
      this.loader.dashboard.general = true;
      try
      {       
        const response = await AuthService.getDashboard('/verification/general/stats');

        if(response.data!==undefined)
        {
          // # Debugger
          console.log('# Debugger -  check getGeneralStats()');
          console.log(response.data);

          this.pensioners.activity.month = response.data.verificationSummary.month;
          this.pensioners.activity.week  = response.data.verificationSummary.week;
          this.pensioners.activity.today = response.data.verificationSummary.today;
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.dashboard.general = false;
      }
    },

    async getRecentVerifications()
    {
      this.loader.dashboard.recent_verification = true;
      
      try
      {       
        const response = await AuthService.getDashboard('/verification/recent/10');

        if(response.data!==undefined)
        {
          // # Debugger
          console.log('# Debugger -  check getRecentVerifications()');
          console.log(response.data);

          response.data.forEach((item, i)=>{
            let temp = {
              id : item.id,
              type : 'new',
              photo_link : item.photoLink,
              name : item.firstName+' '+item.lastName,
              area : item.locationDistrict,
              updated_at : item.updatedAt
            }

            this.pensioners.recent.push(temp);
          });

          // # Debugger
          console.log('# Debugger - check [ pensioners.recent ]');
          console.log(this.pensioners.recent);
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.dashboard.recent_verification = false;
      }
    },

    async getPensionerStats()
    { 
      this.loader.dashboard.pensioners = true;
      
      try
      {       
        const response = await AuthService.getDashboard('/verification/pensioners');

        if(response.data!==undefined)
        {
          // # Debugger
          // console.log('# Debugger -  check getPensionerStats()');
          // console.log(response.data);
          
          this.progress_bar.total = response.data.total;
          this.progress_bar.verified = response.data.verified;

          if(this.progress_bar.verified>0){
            this.progress_bar.percent = this.handlePercentage({ num : this.progress_bar.verified, denum : this.progress_bar.target});
          }
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.dashboard.pensioners = false;
      }
    },

    async getBankStats(type = 'amount')
    {
      this.loader.dashboard.bank = true;
      
      try
      {       
        const response = await AuthService.getDashboard('/verification/bank');

        if(response.data!==undefined)
        {
          // # Debugger
          console.log('# Debugger -  check getBankStats()');
          console.log(response.data);

          let temp_bank = response.data;

          switch(type)
          {
            case 'amount' : 
                temp_bank.sort(function(a, b) { return parseInt(b.pension_amount) - parseInt(a.pension_amount); }); 
            break;
            case 'count'  :
                temp_bank.sort(function(a, b) { return parseInt(b.pensioners_count) - parseInt(a.pensioners_count); });
            break;
          }

          this.pensioners.bank.data = {
              labels : [],
              datasets : []
          };

          for(var i=0; i<5; i++)
          {
             this.pensioners.bank.data.labels[i] = temp_bank[i].bank_name;

             if(type=='amount'){
              this.pensioners.bank.data.datasets[i] = parseInt(temp_bank[i].pension_amount);
              this.pensioners.bank.title = 'By amount';
             }else if(type=='count'){
              this.pensioners.bank.data.datasets[i] = parseInt(response.data[i].pensioners_count);
              this.pensioners.bank.title = 'By Pensioners count';
             }
          }
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.dashboard.bank = false;
      }      
    },

    async getActivityByCenter()
    {
      this.loader.dashboard.center = true;

      try
      {       
        const response = await AuthService.getDashboard('/verification/center');

        if(response.data!==undefined)
        {
          // # Debugger
          console.log('# Debugger -  check getActivityByCenter()');
          console.log(response.data);

          // Sort and set bar chat
          if(response.data.length>0)
          {
              this.pensioners.center.all = response.data;
              this.pensioners.center.top3 = response.data.slice(0, 3);
              const center = response.data;

              // Sort
              center.sort(function(a, b) { return b.total - a.total; });
              console.log(center);

              // this.dashboard.areas.top3 = area.slice(0, 3);
              // this.dashboard.areas.all  = area;

              this.pensioners.center.chart = {
                    categories : [],
                    datasets : [
                        {
                          name : 'Center',
                          data : [],
                        },
                    ]
              }

              for(var i = 0; i<center.length; i++)
              {
                this.pensioners.center.chart.categories[i] = center[i].location_lga;
                this.pensioners.center.chart.datasets[0].data[i] = center[i].total;
              }
          }
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.dashboard.center = false;
      }
    },

    async getLgaOnRetirment(type = 'verified')
    {
      this.loader.dashboard.retirement.lga  = true;
      this.pensioners.retirement.lga.filter = type;

      try
      {       
        const response = await AuthService.getDashboard('/verification/lga/amount/');

        if(response.data!==undefined)
        {

          // Sort and set bar chat
          if(response.data.length>0)
          {
              this.pensioners.retirement.lga.all = response.data;
              const lga = response.data;

              this.pensioners.retirement.lga.chart = {
                  categories : [],
                  datasets : [
                      {
                        name : 'LGA On Retirement',
                        data : [],
                      },
                  ]
              }

              const range = 20; // lga.length

              for(var i = 0; i<range; i++)
              {
                this.pensioners.retirement.lga.chart.categories[i] = lga[i].ministry_on_retirement;
                this.pensioners.retirement.lga.chart.datasets[0].data[i] = (type=='verified')? lga[i].pensioners_count : lga[i].pending_pensioners_count;
              }
          }
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.dashboard.retirement.lga = false;
      }
    },

    async getMdaOnRetirment(type = 'verified')
    {
      this.loader.dashboard.retirement.mda  = true;
      this.pensioners.retirement.mda.filter = type;

      try
      {       
        const response = await AuthService.getDashboard('/verification/mda/amount/');

        if(response.data!==undefined)
        {
          // Sort and set bar chat
          if(response.data.length>0)
          {
              this.pensioners.retirement.mda.all = response.data;
              const mda = response.data;

              this.pensioners.retirement.mda.chart = {
                  categories : [],
                  datasets : [
                      {
                        name : 'MDA On Retirement',
                        data : [],
                      },
                  ]
              }

              for(var i = 0; i<20; i++)
              {
                
                let dataset = 0;

                if(type=='verified'){
                  if(parseInt(mda[i].pensioners_count)>0)
                  {
                    dataset = mda[i].pensioners_count;
                  }
                }else if(type=='not-verified')
                {
                  if(parseInt(mda[i].pending_pensioners_count)>0)
                  {
                    dataset = mda[i].pending_pensioners_count;
                  }
                }

                if(dataset>0)
                {
                  console.log('# Debugger type '+type);
                  console.log('- category : '+mda[i].ministry_on_retirement);
                  console.log('- data :'+dataset);

                  this.pensioners.retirement.mda.chart.categories[i] = mda[i].ministry_on_retirement;
                  this.pensioners.retirement.mda.chart.datasets[0].data[i] = dataset;
                }
              }
          }
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.dashboard.retirement.mda = false;
      }
    },

    async getPensionAmount()
    {
      this.loader.dashboard.pension = true;

      try
      {       
        const response = await AuthService.getDashboard('/pension/amount');

        if(response.data!==undefined)
        {
          console.log('# Debugger -  check getPensionAmount()');
          console.log(response.data);

          // Sort and set bar chat
          if(response.data!==null)
          {
            console.log('# Debugger -  check getPensionAmount()');
            console.log(response.data);

            this.pensioners.amount.all = response.data;
            this.pensioners.amount.chart = {
              labels : [],
              datasets : []
            };

            let amount = response.data;

            this.pensioners.amount.chart.labels[0] = 'STATE';
            this.pensioners.amount.chart.datasets[0] = parseInt(amount.state_total);

            this.pensioners.amount.chart.labels[1] = 'LGA';
            this.pensioners.amount.chart.datasets[1] = parseInt(amount.lga_total);        
          }
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.dashboard.pension = false;
      }      
    },

    async getGenderData()
    {
      this.loader.dashboard.gender = true;

      try
      {       
        const response = await AuthService.getDashboard('/verification/gender/stats');

        if(response.data!==undefined)
        {
          console.log('# Debugger -  check getGenderData()');
          console.log(response.data);

          if(response.data.length>0)
          {
            this.pensioners.gender.all = response.data;
            const gender = response.data;

            this.pensioners.gender.chart = {
                labels : [],
                datasets : []
            };

            for(var i = 0; i<gender.length; i++)
            {
              this.pensioners.gender.chart.labels[i] = gender[i].gender;
              this.pensioners.gender.chart.datasets[i] = parseInt(gender[i].total);
            }

            console.log('# Debugger -  check getGenderData()');
            console.log(response.data);
          }
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.dashboard.gender = false;
      }  
    },

    async getStateStats(type = 'verified')
    {
      this.loader.dashboard.overall = true;

      try
      {       
        const response = await AuthService.getDashboard('/pension/state/stats');

        if(response.data!==undefined)
        {
          console.log('# Debugger -  check getStateStats()');
          console.log(response.data);

          if(response.data!==null)
          {
            this.pensioners.overall.state.data = response.data;
            this.pensioners.overall.state.verified = response.data.verified;
            this.pensioners.overall.state.not_verified = response.data.pending;

            this.getLgaStats(type);
          }
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
        this.loader.dashboard.overall = false;
      }  
    },

    async getLgaStats(type)
    {
      this.loader.dashboard.overall = true;

      try
      {       
        const response = await AuthService.getDashboard('/pension/lga/stats');

        if(response.data!==undefined)
        {
          console.log('# Debugger -  check getLgaStats()');
          console.log(response.data);

          if(response.data!==null)
          {
            this.pensioners.overall.lga.data = response.data;
            this.pensioners.overall.lga.verified = response.data.verified;
            this.pensioners.overall.lga.not_verified = response.data.pending;

            this.getallExercise(type);
          }
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      } 
      finally
      {
        this.loader.dashboard.overall = false;
      }
    },

    /**
     * New General Methods
     * 
     * @description : N/A 
     */
    handlePercentage(data = null, type = null)
    {
       let percentage = 0;

       if(data!==null)
       {
         percentage = (parseInt(data.num) / parseInt(data.denum) ) * 100;
       }

       return percentage;
    },

    styleSmallTable()
    {
      return 'border-left:1px solid #EEE; padding-left:9px';
    },

    fullMonth()
    {
       return Helper.getFullMonth();
    },

    truncate(str, n = 11)
    {
      return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    },

    getallExercise(type=null)
    {
        this.pensioners.overall.type = type;

        this.pensioners.overall.chart = {
          labels : [],
          datasets : []
        };

        let datasets_temp = {
          state : 0,
          lga : 0
        }

        if(type=='verified')
        {
          datasets_temp.state = this.pensioners.overall.state.verified;
          datasets_temp.lga   = this.pensioners.overall.lga.verified;
        }
        else if(type=='not-verified')
        {
          datasets_temp.state = this.pensioners.overall.state.not_verified;
          datasets_temp.lga   = this.pensioners.overall.lga.not_verified;
        }

        this.pensioners.overall.chart.labels[0] = 'STATE';
        this.pensioners.overall.chart.datasets[0] = parseInt(datasets_temp.state);

        this.pensioners.overall.chart.labels[1] = 'LGA';
        this.pensioners.overall.chart.datasets[1] = parseInt(datasets_temp.lga); 

        console.log('# Debugger - check getallExercise [ charts ] :');
        console.log(this.pensioners.overall.chart);
    }, 
  }
}
</script>