<template>
    <div class="report-holder-child">
        <loader v-if="isSearchLoading" style="z-index:10000" object="#344767" color1="#ffffff" color2="#2152ff" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <div class="card mb-4">
            <div class="card-header pb-0">
                <div class="row">
                    <div class="col-md-8 d-flex align-items-center">
                        <h6><i class="fas fa-chart-area"></i> General Report</h6>
                    </div>
                </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="filter-records-holder">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="py-3 text-xs">
                            <form @submit.prevent="SubmitSearchForm" class="px-4 pb-3">
                                <div class="row"> 
                                    <div class="col-md-9 mt-4">
                                        <label> Filter By Name, BVN, NIN or Phone No.</label>
                                        <input type="text" name="searchInput" v-model="search_input" class="form-control" placeholder="enter name, phone No, bvn, or NIN" >
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> 
                                            Filter by employment
                                            &nbsp;
                                            <vsud-spinner-small v-if="isGetAreaLoading"></vsud-spinner-small>
                                        </label>
                                        <select name="role" class="form-control" v-model="type_id" >
                                            <option value="" :selected="true" style="color:#ccc"> 
                                                <span>All </span>
                                            </option>
                                            <option value="0">State</option>
                                            <option value="1">LGA</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 mt-4">
                                        <label> 
                                            Verification Center
                                            &nbsp;
                                            <vsud-spinner-small v-if="isGetLgaLoading"></vsud-spinner-small>
                                        </label>
                                        <select name="role" class="form-control" v-model="location_lga_name" >
                                            <option value="" :selected="true" style="color:#ccc"> 
                                                <span v-if="isGetLgaLoading">
                                                   Loading ... 
                                                </span>
                                                <span v-else>
                                                    . Select
                                                </span>
                                            </option>
                                            <option 
                                                v-for="(data, index) in locationLga"
                                                :key="index" 
                                                :value="data.location_lga">
                                                {{ data.location_lga }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 mt-4">
                                        <label> 
                                            Banks
                                            &nbsp;
                                            <vsud-spinner-small v-if="isGetLgaLoading"></vsud-spinner-small>
                                        </label>
                                        <select name="role" class="form-control" v-model="bank.name" >
                                            <option value="" :selected="true" style="color:#ccc"> 
                                                <span v-if="isGetBankLoading">
                                                   Loading ... 
                                                </span>
                                                <span v-else>
                                                    . Select
                                                </span>
                                            </option>
                                            <option 
                                                v-for="(data, index) in bank.data"
                                                :key="index" 
                                                :value="data">
                                                {{ data }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-4 mt-4">
                                        <label> 
                                            LGAs
                                            &nbsp;
                                            <vsud-spinner-small v-if="isGetListLgaLoading"></vsud-spinner-small>
                                        </label>
                                        <select name="role" class="form-control" v-model="localgovs.name" >
                                            <option value="" :selected="true" style="color:#ccc"> 
                                                <span v-if="isGetBankLoading">
                                                   Loading ... 
                                                </span>
                                                <span v-else>
                                                    . Select
                                                </span>
                                            </option>
                                            <option 
                                                v-for="(data, index) in localgovs.data"
                                                :key="index" 
                                                :value="data">
                                                {{ data }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> Filter By Start Date</label>
                                        <input name="role" type="date" v-model="start_date" class="form-control" placeholder="Filter by date  : Start range"  />
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> Filter By End Date</label>
                                        <input name="role" type="date" v-model="end_date" class="form-control" placeholder="Filter by date  : End range"  />
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label> Filter By Status</label>
                                        <select name="status" v-model="status" class="form-control">
                                            <option value="" :selected="true" style="color:#ccc">Filter by status</option>
                                            <option value="completed">Verified</option>
                                            <option value="pending">Not-verified</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 mt-4">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <div class="row">
                                            <div class="col-md-6 mb-4"><VsudButton class="form-control" color="info" variant="gradient"> Submit </VsudButton></div>
                                            <div class="col-md-6"><span class="btn mb-0 bg-gradient-secondary btn-md form-control" @click="SearchReset">Reset</span></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card" v-if="isSearchLoading==false && message=='' && isSearchActive==true">
            <div class="card-header pb-0">
                <div class="row">
                    <div class="col-md-8 d-flex align-items-center">
                        <div class="pt-1 pb-3">
                            <b><i class="fas fa-files"></i> Total Records :</b> {{rows}}, &nbsp;&nbsp;&nbsp; <b><i class="fas fa-file"></i> Per Page :</b> {{perPage}}
                        </div>
                    </div>
                    <div 
                        v-if="(loggedInUser=='superadmin'||loggedInUser=='admin')&&export_active==true" 
                        class="col-md-4" 
                        style="text-align:right">
                        <div class="row">
                            <div class="col-md-1">
                                <vsud-spinner-small v-if="isExportLoading"></vsud-spinner-small>  
                            </div>
                            <div class="col-md-6 mb-4">
                                <select :disabled="isExportLoading==true" name="status" class="form-control" v-model="export_type">
                                    <option value="csv" :selected="true">Export type ( csv )</option>                                            
                                    <option value="xls">xls</option>
                                    <option value="xlsx">xlsx</option>
                                </select>
                            </div>
                            <div class="col-md-5">
                                <button :disabled="isExportLoading==true" @click="exportCsvHref" class="btn mb-0 bg-gradient-info btn-mdt form-control">Export {{export_type}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body px-0 pt-0 pb-2">
                <div class="table-data-holder">
                    <div class="table-responsive p-0">
                        <div class="filter-records-holder">
                            <div class="row">
                                <div class="col-md-12">
                                </div>
                            </div>
                        </div>
                        <table class="table align-items-center mb-0">
                            <thead>
                            <tr>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                GPS
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Firstname
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Lastname
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Phone No.
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                NIN
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                BVN
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Lga
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Designation
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Grade Level
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Contract type
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Monthly Pension
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Bank
                                </th>  
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Type
                                </th>
                                <th
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Status
                                </th>                
                                <th
                                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                Created At
                                </th>
                                <th class="text-secondary opacity-7"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="data in TableData"  :key="data.id">
                                <td>
                                <div class="d-flex px-3 py-1">
                                    <h6 class="mb-0 text-sm">
                                    <span v-if="data.locationCoordinates!==null">
                                        <span v-if="checkGpsExist(data.locationCoordinates).status==true">
                                        <i class="fas fas fa-map" v-if="checkGpsExist(data.locationCoordinates).is_polygon==true"></i>
                                        <i class="fas fa-map-marker-alt" v-else></i>
                                        </span>
                                        <span v-else>
                                        ...
                                        </span>
                                    </span>
                                    <span v-else>
                                        ...
                                    </span>
                                    </h6>
                                </div>
                                </td>
                                <td>
                                <div class="d-flex px-3 py-1">
                                    <h6 class="mb-0 text-sm">
                                    <span class="mb-0 text-sm" v-if="data.firstName!==null||data.firstName!==''||data.firstName!==undefined">
                                        {{data.firstName}}
                                    </span>
                                    <span v-else>...</span>
                                    </h6>
                                </div>
                                </td>
                                <td>
                                <div class="d-flex px-3 py-1">
                                    <span class="mb-0 text-sm" v-if="data.lastName!==null&&data.lastName!==''">
                                        {{data.lastName}}
                                    </span>
                                    <span v-else>...</span>
                                </div>
                                </td>
                                <td>
                                <div class="d-flex px-3 py-1">
                                    <h6 class="mb-0 text-sm">
                                        <span v-if="data.phoneNo!==null&&data.phoneNo!==''">
                                        {{data.phoneNo}}
                                        </span>
                                        <span v-else>...</span>
                                    </h6>
                                </div>
                                </td>
                                <td>
                                <div class="d-flex px-3 py-1">
                                    <div class="mb-0 text-sm">
                                        <span v-if="data?.nin!==undefined">
                                        <span v-if="data.nin!==null&&data.nin!==''">
                                            {{data.nin}}
                                        </span>
                                        <span v-else>. . .</span>
                                        </span>
                                        <span v-else>
                                        . . .
                                        </span>
                                    </div>
                                </div>
                                </td>
                                <td>
                                <div class="d-flex px-3 py-1">
                                    <h6 class="mb-0 text-sm">
                                        <span v-if="data?.bvn!==undefined">
                                        <span v-if="data.bvn!==null&&data.bvn!==''">
                                        {{data.bvn}}
                                        </span>
                                        <span v-else>. . .</span>
                                        </span>
                                        <span v-else>
                                        . . .
                                        </span>
                                    </h6>
                                </div>
                                </td>
                                <td>
                                <div class="d-flex px-3 py-1">
                                    <h6 class="mb-0 text-sm">
                                        <span v-if="data.lga!==null&&data.lga!==undefined">
                                        {{ data.lga }}
                                        </span>
                                        <span v-else>
                                        ...
                                        </span>
                                    </h6>
                                </div>
                                </td>
                                <td>
                                <div class="d-flex px-3 py-1">
                                    <h6 class="mb-0 text-sm">
                                        <span v-if="data?.designationOnRetirement!==undefined">
                                        <span v-if="data.designationOnRetirement!==null&&data.designationOnRetirement!==''">
                                            {{data.designationOnRetirement}}
                                        </span>
                                        <span v-else>. . .</span>
                                        </span>
                                        <span v-else>. . .</span>
                                    </h6>
                                </div>
                                </td>
                                <td>
                                <div class="d-flex px-3 py-1">
                                    <h6 class="mb-0 text-sm">
                                        <span v-if="data?.gradeLevel!==undefined">
                                        <span v-if="data.gradeLevel!==null&&data.gradeLevel!==''">
                                            {{data.gradeLevel}}
                                        </span>
                                        <span v-else>. . .</span>
                                        </span>
                                        <span v-else>. . .</span>
                                    </h6>
                                </div>
                                </td>
                                <td>
                                <div class="d-flex px-3 py-1">
                                    <h6 class="mb-0 text-sm">
                                        <span v-if="data.contractType!==null&&data.contractType!==undefined">
                                        {{data.contractType}}
                                        </span>
                                        <span v-else>...</span>
                                    </h6>
                                </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            <span v-if="data.monthlyPension!==null&&data.monthlyPension!==undefined">
                                            NGN {{data.monthlyPension.toLocaleString("en-us")}}
                                            </span>
                                            <span v-else>...</span>
                                        </h6>
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-3 py-1">
                                        <h6 class="mb-0 text-sm">
                                            <span v-if="data.bankName!==null&&data.bankName!==undefined">
                                                {{ data.bankName }}
                                            </span>
                                            <span v-else>...</span>
                                        </h6>
                                    </div>
                                </td>
                                <td class="align-middle text-center text-sm">
                                <vsud-badge v-if="data.isLga==1" color="info" variant="gradient" size="sm">lga pensioner</vsud-badge>
                                <vsud-badge v-else color="warning" variant="gradient" size="sm">state pensioner</vsud-badge>
                                </td>
                                <td class="align-middle text-center text-sm">
                                <vsud-badge v-if="data.status=='completed'" color="success" variant="gradient" size="sm">verified</vsud-badge>
                                <vsud-badge v-else color="danger" variant="gradient" size="sm">not-verified</vsud-badge>
                                </td>
                                <td class="align-middle text-center">
                                <span class="text-secondary text-xs font-weight-bold" v-if="data.updatedAt!==null">
                                    {{ moment(data.updatedAt).format("ddd MMM DD, YYYY [at] HH:mm a") }}
                                </span>
                                <span class="text-secondary text-xs font-weight-bold" v-else>...</span>
                                </td>
                                <td class="align-middle">
                                <router-link
                                    class="text-secondary font-weight-bold text-xs"
                                    data-toggle="tooltip"
                                    data-original-title="View Pensioner"
                                    target="_blank"
                                    :to="{name:'View Pensioner' , params: {id:data.id}}">
                                    <i class="fa fa-eye"></i> View
                                </router-link>
                                &nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="p-3">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @click="handlePageChange(currentPage)" aria-controls="my-table"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card nodata-holder" v-else>
            <div class="card-body px-0 pt-0 pb-2">
                <vsud-nodata :message="message"></vsud-nodata>
            </div>
        </div>
    </div>
</template>
<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudButton from "@/components/VsudButton.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import VsudPagination from "@/components/VsudPaginationNew.vue";
import VsudBadge from "@/components/VsudBadge.vue";
import VsudSpinnerSmall from "@/components/VsudSpinnerSmall.vue";
import moment from "moment";

export default {
  name: "general-reports",
  data()
  {
    return {

      /**
       * New data bind
       * 
       * @description : N/A 
       */
      TableData : [],
      message : 'No search record yet',
      loggedInUser : '',
      status : '',
      type_id : '',
      start_date : '',
      end_date : '',
      mda_id : '',
      page : '',
      localgov_id : '',
      location_lga_name : '',
      search_input : '',
      export_active : false,
      export_url  : '',
      export_param : '',
      export_type : 'csv',
      export_file_name : '',
      loggedInConsultant : [], 
      locationLga : [],
      perPage: 0,
      currentPage: 0,
      rows : 0,
      isSearchLoading : false,
      isSearchActive : false,
      isMDALoading : false,
      isLGALoading : false,
      isGetLgaLoading : false,
      isGetBankLoading : false,
      isGetListLgaLoading : false,

      /**
       * Old Data Bind
       * 
       * @description : N/A 
       */
      phases : [],
      areas : [],
      states : [],
      wards : [],
      sectors : [],
      localgovs : { data : [], name : '' },
      schemes : [],
      consultants : [],
      enumurators : [],
      bank : { data : [], name : '' },
      state_id : '',
      area_id : '',
      ward_id : '',
      phase_id : '',
      sector_id : '',
      consultant_id : '',
      enumerator_id : '',
      isStateLoading : false,
      isConsultantLoading : false,
      isSchemeLoading : false,
      isExportLoading : false,
      isGetAreaLoading : false,
      isGetPhaseLoading : false,
      isGetBusinessSectorLoading : false,
    }
  },

  components: 
  {
    VsudSpinner,
    VsudNodata,
    VsudPagination,
    VsudBadge,
    VsudButton,
    VsudSpinnerSmall,
  },

  created()
  {
    this.getUserClass();
    this.getLocationLga();
    this.getBankList();
    this.getLgaLists();
    this.moment = moment;
  },

  methods :
  {
    /**
     * New methods
     * 
     * @description : N/A 
     */
    async GetBenficiaries()
    {  
      this.export_active = false;
      this.isSearchLoading = true;
      const searchUrl = (this.search_input!=='')? '&search='+this.search_input : '';
      const status = (this.status!=='')? '&status='+this.status : '';
      const pageUrl = (this.page!=='')? '&page='+this.page : '';
      const type = (this.type_id!=='')? '&is_lga='+this.type_id : '';
      const startDate = (this.start_date!=='')? '&date_filter[]='+this.start_date : '';
      const endDate = (this.end_date!=='')? '&date_filter[]='+this.end_date : '';
      const locationLga = (this.location_lga_name!=='')? '&location_lga='+this.location_lga_name : '';
      const bankName = (this.bank.name!=='')? '&bank='+this.bank.name : '';
      const lgaName = (this.localgovs.name!=='')? '&lga='+this.localgovs.name : '';
      const perPageUrl = '?perPage=100';
      const PreSetUrl = searchUrl+status+type+startDate+endDate+locationLga+bankName+lgaName;
      const paramUrl = perPageUrl+PreSetUrl+pageUrl;

      // console.log('[host]/'+paramUrl);

      try{
          const response = await AuthService.getPensioners(paramUrl);

          if(response.data.length>0)
          {
            this.TableData = response.data;
            this.perPage = response.meta.pagination.perPage;
            this.currentPage = response.meta.pagination.currentPage;
            this.rows = response.meta.pagination.total;
            this.message = '';

            const export_file_name = 'Reports'+this.getExportFilename();
            this.export_param = PreSetUrl+'&filename='+export_file_name;
            this.export_file_name = export_file_name;
            this.export_active = true;
          }
          else
          {
            this.message = 'No data please try again';
          }
       }
       catch(error)
       {
        this.message  = error.message;
        console.log(error);
       }finally{
        this.isSearchLoading = false;
       }
    },

    async exportCsvHref()
    {
        this.isExportLoading = true;

        try
        {
            const param = '?type='+this.export_type+this.export_param;
            const blob  = await AuthService.getExportUrlEndpoint(param);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.export_file_name+'.'+this.export_type;
            link.click();

            this.isExportLoading = false;
        }
        catch(error)
        {
            this.isExportLoading = false;

            if(error)
            { 
                console.log(error);

                alert('An error occured downloading report');
            }
        }
    },

    async getLocationLga()
    {
        this.isGetLgaLoading = true;

        try
        {       
            const response = await AuthService.getDashboard('/verification/center');

            if(response.data!==undefined)
            {
                if(response.data.length>0)
                {
                    this.locationLga = response.data;
                }
            }
        }
        catch(error)
        {
            console.log(error);
            AuthService.CheckResponseError(error);
        }
        finally
        {
            this.isGetLgaLoading = false;
        }        
    },

    async getBankList()
    {
        this.isGetBankLoading = true;

        try
        {       
            const response = await AuthService.getBanks();

            if(response.banks!==undefined)
            {
                if(response.banks.length>0)
                {
                    this.bank.data = response.banks;
                }
            }
        }
        catch(error)
        {
            console.log(error);
            AuthService.CheckResponseError(error);
        }
        finally
        {
            this.isGetBankLoading = false;
        } 
    },

    async getLgaLists()
    {
        this.isGetListLgaLoading = true;

        try
        {       
            const response = await AuthService.getLgas();

            // console.log(response);

            if(response.lgas!==undefined)
            {
                if(response.lgas.length>0)
                {
                    this.localgovs.data = response.lgas;
                }
            }
        }
        catch(error)
        {
            console.log(error);
            AuthService.CheckResponseError(error);
        }
        finally
        {
            this.isGetListLgaLoading = false;
        } 
    },

    handlePageChange(value) 
    {
        this.page = value;
        this.GetBenficiaries();
    },

    SubmitSearchForm()
    {
        this.isSearchActive = true; 
        this.GetBenficiaries();
    },

    SearchReset()
    {
        this.search_input = '';
        this.type_id = '';
        this.mda_id = '';
        this.localgov_id = '';
        this.start_date = '';
        this.end_date = '';
        this.status = '';
        this.isSearchActive = false;
        this.TableData = [];
        this.message= 'No search record yet';
    },

    getUserClass()
    {
      if(this.$store.getters.isLoggedIn)
      {
        this.loggedInUser = this.$store.getters.getUser.primaryRole.name;
      }
    },

    getExportFilename()
    {

        var ExportUrl = '';

        if(this.search_input!=='')
        {
            ExportUrl += '-'+this.search_input;
        }

        if(this.status!=='')
        {
            ExportUrl += (this.status=='completed')? '-Verified' : '-NotVerified';
        }

        if(this.type_id!=='')
        {
            ExportUrl += (this.type_id==1)? '-LgaPensioner' : '-StatePensioner';
        }

        // Start Date
        if(this.start_date!=='')
        {
            ExportUrl += '-'+this.start_date;
        }

        // Start Date
        if(this.end_date!=='')
        {
            ExportUrl += '-'+this.end_date;
        }

        return ExportUrl;

    },

    checkGpsExist(data)
    {
        var gps = { status : false, is_polygon : false};

        if(data.lat!==null&&data.lng!==null)
        {
            gps.status = true;
        }
       
        return gps;
    },

    /**
     * Old Methods 
     * 
     * @description : To be reviewed  
     */
    async GetPhase()
    {
        this.isGetPhaseLoading = true;

        try{
            const res = await AuthService.getPhases();
            this.phases = res.data;

            console.log('# [ Debugger ] - check phases :');
            console.log(res.data);
        }
        catch(error){
            console.log(error);
        }
        finally{
            this.isGetPhaseLoading = false;
        }
    },

    async GetBusinessSector()
    {
        this.isGetBusinessSectorLoading = true; 

        try{
            const res = await AuthService.getBusinessSector();
            this.sectors = res.data;

            console.log(' # [ Debugger ] - check Get bussiness sector :');
            console.log(res.data);
        }   
        catch(error){
            console.log(error);
        }finally{
            this.isGetBusinessSectorLoading = false; 
        }
    },
  },
};
</script>
