import { createRouter, createWebHistory } from "vue-router";

// General Components
import Dashboard from "@/views/Dashboard.vue";
import Profile from "@/views/Profile.vue";

// Users Components
import UserList from "@/views/users/ListUser.vue";
import UserAdd from "@/views/users/Add.vue";
import UserEdit from "@/views/users/Edit.vue";

// State Components
import ListState from "@/views/state/List.vue";
import AddState from "@/views/state/Add.vue";
import EditState from "@/views/state/Edit.vue";

// Local Gov Components
import ListLocalGov from "@/views/localgov/List.vue";
import AddLocalGov from "@/views/localgov/Add.vue";
import EditLocalGov from "@/views/localgov/Edit.vue";

// Ward Components
import ListWard from "@/views/ward/List.vue";
import AddWard from "@/views/ward/Add.vue";
import EditWard from "@/views/ward/Edit.vue";

// Beneficiary Components
import ListBeneficiary from "@/views/beneficiaries/List.vue";
import ViewBeneficiary from "@/views/beneficiaries/View.vue";

// Reports
import GeneralReports from "@/views/reports/enumeration/Main.vue";
import PensionCountBank from "@/views/reports/PensionersBankCount.vue";
import PensionMdaOnRetirement from "@/views/reports/PensionMdaOnRetirement.vue";
import PensionLgaOnRetirement from "@/views/reports/PensionLgaOnRetirement.vue";
import PensionActivityByCenter from "@/views/reports/PensionActivityByCenter.vue"

// Auth Component
import LogIn from "@/views/auth/LoginPage.vue";
import NotAuthorized from "@/views/auth/NotAuhtorized.vue";
import Auth from "@/services/auth.interceptor.js";

const routes = [

  /*
  |--------------------------------------------------------------------------
  | New Routes
  |--------------------------------------------------------------------------
  |
  | These routes are the onces made available after code clean up and new once
  | created for only pension projects
  |
  */

 /**
  * General Routes 
  * 
  * @description : Dashboard, & Login
  */
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { title: 'Skills - MyApp' }
  },
  {
    path: "/auth/login",
    name: "Login",
    component: LogIn
  },

 /**
  * New Reports
  * 
  * @description : N/A 
  */
  {
    path: "/reports/general/pension/count",
    name: "Pension Amount By Bank",
    component : PensionCountBank
  },
  {
    path: "/reports/general/pension/mda/retirement",
    name: "MDA On Retirement",
    component : PensionMdaOnRetirement
  },
  {
    path: "/reports/general/pension/lga/retirement",
    name: "LGA On Retirement",
    component : PensionLgaOnRetirement
  },
  {
    path: "/reports/pension",
    name: "Pension Reports",
    component : GeneralReports
  },
  {
    path: "/reports/general/pension/activity/center",
    name: "Pension activity by center",
    component : PensionActivityByCenter
  },

 /**
  * State Routes
  *  
  * @description : List, Add, Edit
  */
  {
    path: "/states",
    name: "States",
    component : ListState,
    children : [
      {
        name : "States",
        path : "back/:status",
        component : ListState
      },
      {
        name : "States",
        path : "pages/:pgnumber",
        component : ListState
      }
    ]
  },
  {
    path: "/state/edit/:id",
    name: "Edit State",
    component : EditState
  },
  {
    path: "/state/add",
    name: "Add State",
    component : AddState
  },

 /**
  * Local Governments 
  * 
  * @description : List, Add, Edit
  */
  {
    path: "/local-governments",
    name: "Local Governments",
    component : ListLocalGov,
    children : [
      {
        name : "Local Governments",
        path : "back/:status",
        component : ListLocalGov
      }
    ]
  },
  {
    path: "/local-governments/edit/:id",
    name: "Edit Local Government",
    component : EditLocalGov
  },
  {
    path: "/local-governments/add",
    name: "Add Local Government",
    component : AddLocalGov
  },

 /**
  * Wards 
  * 
  * @description : List, Add, Edit
  */
  {
    path: "/wards",
    name: "Wards",
    component : ListWard,
    children : [
      {
        name : "Wards",
        path : "back/:status",
        component : ListWard
      }
    ]
  },
  {
    path: "/ward/edit/:id",
    name: "Edit Ward",
    component : EditWard
  },
  {
    path: "/ward/add",
    name: "Add Ward",
    component : AddWard
  },

 /**
  * Users Route 
  * 
  * @description : List, Add, Edit
  */
  {
    path: "/user",
    name: "Users",
    component : UserList,
    children : [
      {
        name : "Users",
        path: "back/:status",
        component : UserList
      }
    ]
  },
  {
    path: "/user/edit/:id",
    name: "Edit User",
    component : UserEdit
  },
  {
    path: "/user/add",
    name: "Add User",
    component : UserAdd
  },

 /**
  * Pensioners
  * 
  * @description : List, Add, Edit
  */
  {
    path: "/pensioners",
    name: "Pensioners",
    component : ListBeneficiary,
    children : [
      {
        name : "Pensioners",
        path : "back/:status",
        component : ListBeneficiary
      },
      {
        name : "Pensioners",
        path : "pages/:pgnumber",
        component : ListBeneficiary
      }
    ]
  },
  {
      path: "/pensioners/view/:id",
      name: "View Pensioner",
      component : ViewBeneficiary
  },

 /**
  * Profile
  * 
  * @description : Users Profile 
  */
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  
 /**
  * Not Authorized
  * 
  * @description : *
  */
  {
    path: "/error/not-authorized",
    name: "Not-Authorized",
    component: NotAuthorized
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to,from)=>{
  
  const canAccess = Auth.canUserAccess(to.name);
  
  if (canAccess=='back-to-login')
  { 
    return '/auth/login';
  }
  else if(canAccess=='not-authorized')
  {
    return '/error/not-authorized';
  }

});


export default router;
