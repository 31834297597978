<template>
    <div id="card">
        <div id="chart">
            <apexchart :type="chartType" height="520" :options="chartOptions" :series="series"></apexchart>
        </div>
    </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";
export default {
    props : {
        chartData : {
            type : Object,
            default : []
        },
        chartType : {
          type : String,
          default : 'pie'
        }
    },
    components: {
        apexchart : VueApexCharts,
    },
    data(){ 
        return {
          series: this.chartData.datasets, // Sample : [44, 55, 13, 43, 22]
          chartOptions: {
            chart: {
              width: 250,
              type: 'pie',
            },
            labels: this.chartData.labels , // Sample : ['Team A', 'Team B', 'Team C', 'Team D', 'Team E']
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                }
              }
            }],
            legend : { position : 'bottom'}
          },
          
          
      }
    },
};
</script>