<template>
  <div class="home-dashboard">
    <vsud-loader v-if="isRegEnumVerLnLoading"></vsud-loader>
    <div class="home-data" v-else>
      <div class="row">
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12 mb-4">
              <div class="card">
                <div class="pb-0 card-header">
                  <h6>Year / Phase</h6>
                </div>
                <div class="card-body">
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <div 
                              class="input-group-text" 
                              style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                              &nbsp; <i class="fa fa-calendar"></i>
                          </div>
                      </div>
                      <select @change="changePhase()" v-model="byPhase" name="report-type" class="form-control" id="inlineFormInputGroup">
                          <option value="2024"> &nbsp; 2024 ( Phase 1 ) </option>                                           
                      </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 ">
              <div class="card">
                <div class="pb-0 card-header">
                  <h6>Coverage</h6>
                </div>
                <div class="card-body">
                  <table class="table table-bordered">
                    <tr>
                      <td class="text-info" width="140"><i class="fa fa-calendar"></i>Target</td><td :style="styleSmallTable" >
                        26,855
                      </td>
                    </tr>
                    <tr>
                      <td class="text-success"><i class="fa fa-calendar"></i> Local Gov</td><td :style="styleSmallTable" > 
                        371
                      </td>
                    </tr>
                    <tr>
                      <td class="text-warning"><i class="fa fa-calendar"></i> Ward</td><td :style="styleSmallTable" > 
                        34
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12">
              <mini-statistics-card
                title="Total"
                :value="stats.pensioners.total.toLocaleString('en-us')"
                :percentage="{
                  value: '+3%',
                  color: 'text-success',
                }"
                :icon="{
                  component: 'fas fa-user',
                  background: 'bg-gradient-secondary',
                }"
                direction-reverse
              />
            </div>
            <div class="col-md-12">
              <mini-statistics-card
                title="Verified"
                :value="stats.pensioners.verified.toLocaleString('en-us')"
                :percentage="{
                  value: '+5%',
                  color: 'text-success',
                }"
                :icon="{
                  component: 'fas fa-globe',
                  background: 'bg-gradient-info',
                }"
                direction-reverse
              />
            </div>
            <div class="col-md-12">
              <mini-statistics-card
                title="Not-verified"
                :value="stats.pensioners.not_verified.toLocaleString('en-us')"
                :percentage="{
                  value: '+505%',
                  color: 'text-success',
                }"
                :icon="{
                  component: 'ni ni-money-coins',
                  background: 'bg-gradient-success',
                }"
                direction-reverse
              />
            </div>
            <div class="col-md-12">
              <mini-statistics-card
                title="Failed"
                :value="stats.pensioners.failed.toLocaleString('en-us')"
                :percentage="{
                  value: '-2%',
                  color: 'text-danger',
                }"
                :icon="{
                  component: 'ni ni-paper-diploma',
                  background: 'bg-gradient-warning',
                }"
                direction-reverse
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                    <div class="col-6">
                        <img :src="GeepImgBox" :style="{ height : '65px' }" />
                    </div>
              </div>
            </div>
            <div class="card-body pb-5" :style="'font-size:11px; text-align:justify; word-spacing:-2px;'">
              <p>
                The Katsina Pension Verification Exercise endeavors to enhance administrative efficiency and financial accountability by meticulously verifying and scrutinizing pension records, laying the groundwork for improved pension management in Katsina State, Nigeria
              </p>
              <p style="margin-bottom: 9px;">
                Through a comprehensive verification process of pensioners, the government and relevant stakeholders acquire essential data to understand and address the state's pension system's strengths and areas needing improvement.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
              <div class="card">
                <div class="pb-0 card-header">
                  <h6>State</h6>
                </div>
                <div class="card-body small">
                  <table class="table table-bordered">
                    <tr>
                      <td class="text-info" width="140"><i class="fa fa-calendar"></i> Verified</td><td :style="styleSmallTable" >
                        {{stats.state.verified}}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-success"><i class="fa fa-calendar"></i> Not-verified &nbsp;</td><td :style="styleSmallTable" > 
                        {{ stats.state.not_verified }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-warning"><i class="fa fa-calendar"></i> Total</td><td :style="styleSmallTable" > 
                        {{ stats.state.total }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
        </div>
        <div class="col-md-4">
              <div class="card">
                <div class="pb-0 card-header">
                  <h6>LGA</h6>
                </div>
                <div class="card-body small">
                  <table class="table table-bordered">
                    <tr>
                      <td class="text-info" width="140"><i class="fa fa-calendar"></i> Verified</td><td :style="styleSmallTable" >
                        {{ stats.lga.verified }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-success"><i class="fa fa-calendar"></i> Not-verified &nbsp;</td><td :style="styleSmallTable" > 
                        {{stats.lga.not_verified }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-warning"><i class="fa fa-calendar"></i> Total</td><td :style="styleSmallTable" > 
                        {{ stats.lga.total }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
        </div>
        <div class="col-md-4">
              <div class="card">
                <div class="pb-0 card-header">
                  <h6>Pension Amount</h6>
                </div>
                <div class="card-body small">
                  <table class="table table-bordered">
                    <tr>
                      <td class="text-info" width="140"><i class="fa fa-calendar"></i> Verified</td><td :style="styleSmallTable" >
                        {{stats.pension_amount.verified}}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-success"><i class="fa fa-calendar"></i>Not-verified &nbsp;</td><td :style="styleSmallTable" > 
                        {{stats.pension_amount.not_verified}}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-warning"><i class="fa fa-calendar"></i> Total</td><td :style="styleSmallTable" > 
                      {{stats.pension_amount.total}}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-md-12 mb-md-0 mb-4">
            <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6>Activity Map</h6>
                  <div class="text-sm mb-0">
                    By {{ this.map_filter }}
                  </div>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
                  <div class="dropdown float-lg-end pe-4">
                    <a
                      class="cursor-pointer"
                      id="dropdownTable"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i class="fa fa-ellipsis-v text-secondary" aria-hidden="true"></i>
                    </a>
                    <ul
                      class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5"
                      aria-labelledby="dropdownTable">
                      <li @click="mapFilterToggle('by_state')"> &nbsp; . . . .</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" >
                <div class="toggle-new mt-3">
                  <div class="row">
                    <div class="col-md-3 mb-3">
                      <div class="input-group">
                          <div class="input-group-prepend">
                              <div 
                                  class="input-group-text" 
                                  style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                                  &nbsp; <i class="fa fa-chart-area"></i>
                              </div>
                          </div>
                          <select 
                              @change="handleMapChange('map_type')"
                              name="report-type" class="form-control" v-model="map_type">                                       
                              <option value="verified"> &nbsp; Verified</option>
                              <option value="not-verified"> &nbsp; Not-Verified</option>
                          </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="input-group">
                          <div class="input-group-prepend">
                              <div 
                                  class="input-group-text" 
                                  style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #EEE; border-right: 2px solid #DDD;">
                                  &nbsp; <i class="fa fa-check-square"></i>
                              </div>
                          </div>
                          <select 
                              @change="handleMapChange('map_filter')"
                              name="report-type" class="form-control" v-model="map_filter">
                              <option value="population"> &nbsp; Population </option>                                           
                              <option value="amount"> &nbsp; Amount </option>
                          </select>
                      </div>
                    </div>
                  </div>
              </div>
              <div class=" text-center p-5">
                <vsud-spinner v-if="mapActive(loader)"></vsud-spinner>
                <div v-else>
                  <div v-if="map_data==null" style="height:430px;" >
                    <vsud-nodata></vsud-nodata>
                  </div>
                  <simple-maps v-else
                      :dataType="map_type" 
                      mapDataType="pension" 
                      :dataFilter="map_filter" 
                      :dataSet="map_data">
                  </simple-maps>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import VsudLoader from "@/components/VsudLoader.vue";
import VsudSpinner from "@/components/VsudSpinner.vue";
import SimpleMaps from "@/views/components/maps/Simplemaps.vue";
import AuthService from '@/services/auth.service.js';
import Helper from '@/services/auth.helpers.js';
import GeepImgBox from '@/assets/img/logos/02-pension-client.png';
import GeepImgAbstract from '@/assets/img/illustrations/geep-ilus-05.png';
import TraderMoniLogo from '@/assets/img/logos/trader-moni-logo-01.png';
import MarketMoniLogo from '@/assets/img/logos/market-moni-logo-01.png';
import FarmerMoniLogo from '@/assets/img/logos/farmer-moni-logo-01.png';
import Pie from '@/views/components/dashboard/charts/Pie.vue';
import Doughnut from '@/views/components/dashboard/charts/Doughnut.vue';
import VsudNodata from "@/components/VsudNodata.vue";

export default {
  name: "home",
  data() {
    let defmsg = '--';
    return {
      /**
       * Deprecated
       * 
       * @description : Old data sets
       */
      map_data : null,
      map_type : 'verified',
      map_type_data : 'verified',
      map_filter : 'population',
      map_sub_title : 'Breakdown by States',
      dashboard : {
        enumeration : defmsg,
        enum : defmsg,
        updated : defmsg,
        created : defmsg,
        stats :{
          month : defmsg,
          today : defmsg,
          week : defmsg,
        }
      }, 
      Registration : defmsg,
      Enumeration : defmsg,
      Verification : defmsg,
      Loan : defmsg,
      chartSchemeActivity : [],
      Coverage : { states : defmsg, localgovs : defmsg, wards : defmsg },
      Scheme : [],
      GeepImgBox,
      GeepImgAbstract,
      TraderMoniLogo,
      MarketMoniLogo,
      FarmerMoniLogo,
      styleSmallTable : 'border-left:1px solid #EEE; padding-left:9px',
      schemeInfoFontSize : 'font-size:15px',
      isLoading : false,
      isGenderLoading : false,
      isRegEnumVerLnLoading : false,

      /**
       * New data sets
       * 
       * @description : only verifications 
       */
      timer : null,
      stats : {
        pensioners : {
          total : defmsg,
          verified : defmsg,
          failed : defmsg,
          updated : defmsg,
          not_verified : defmsg
        },
        general : {
          verificationSummary : {
            month : defmsg,
            week : defmsg,
            today : defmsg
          }
        },
        state : {
          verified : defmsg,
          not_verified : defmsg,
          total : defmsg,
          amount : 0,
          amount_pending : 0
        },
        lga : {
          verified : defmsg,
          not_verified : defmsg,
          total : defmsg,
          amount : 0,
          amount_pending : 0
        },
        pension_amount : {
          verified : defmsg,
          not_verified : defmsg,
          total : defmsg
        },
        pension_amount_cloud :{
          verified : 0,
          not_verified : 0,
          total : 0
        },
        recent : {},
        bank : []
      },
      byPhase : '2024',
      loader : {
        isGenStats : false,
        pensioners : false,
        bank : false,
        state : false,
        lga : false,
        amount : false
      },
      maps : {
        active : true,
        data : null
      }

    };
  },

  components: {
    MiniStatisticsCard,
    VsudSpinner,
    SimpleMaps,
    Pie,
    Doughnut,
    VsudLoader,
    VsudNodata
  },

  created()
  {
    this.RunDashboard();
    this.timer = setInterval(() => { this.RunDashboard() }, 300000);
  },

  methods :
  {
    RunDashboard()
    {
        this.GetDashboardGeneralStats();
        this.GetDashboardPensioners();
        this.getBankStats();
        // this.getStateStats();
        // this.getLgaStats();
        // console.log('Timer working');
    },

    /**
     *  Old Methods 
     * 
     *  @description : N/A
     */
    async GetMapActivity(sortType='enumerated',sortFilter='by_state',sortTypeData='enumeration')
     {
       this.isLoading = true;
       this.map_sub_title = this.mapFilterTitle(sortFilter);

       try
       {
          const year = (this.byPhase!=='')? '?year='+this.byPhase : '';
          var response = {};

          // # Debug
          console.log('# Debug check GetMapActivity year :');
          console.log(year);

          switch(sortTypeData)
          {
              case 'enumeration'  : response = await AuthService.getCountryEnumerationActivity(year); break;
              case 'registration' : response = await AuthService.getReportRegistrationActivity(year); break;
              case 'loan'         : response = await AuthService.getReportLoanStates(); break;
          }

          this.map_data = { "type" : this.map_type, "filter" : this.map_filter };
          
          if(sortTypeData=='enumeration')
          {

            this.map_data['by_states'] = response.data.enumerationActivityByStates;

            if(sortType=='enumerated')
            {
                response.data.enumerationActivityByStates.sort(function(a, b) { return b.updated - a.updated; });
            }

            if(sortType=='not-enumerated')
            {
                response.data.enumerationActivityByStates.sort(function(a, b) { return b.updated - a.updated; });
            }

            if(sortFilter=='by_state')
            {
                const yearByState = (this.byPhase!=='')? '?year='+this.byPhase : ''; 
                const rescheme = await AuthService.getStateSchemeActivity('?status[]=enumerated&status[]=verified'+yearByState);
                this.map_data['by_schemes'] = rescheme.data.enumerationActivityByStatesBySchemes;
            }

            if(sortFilter=='by_zone')
            { 
                const reszone = await AuthService.getZoneActivity(year);
                this.map_data["by_zones"]  = reszone.data.enumerationActivityByZones;
            }

            if(sortFilter=='by_consultant')
            {
                const resconsultant = await AuthService.getConsultants(year);
                this.map_data["by_consultants"] = resconsultant.data;
            }

          }

          // # Debug
          // console.log('# Debug');
          // console.log('Check : map_data');
          // console.log(this.map_data);
          
          this.isLoading = false;
       }
       catch(error)
       {
          console.log(error);
          AuthService.CheckResponseError(error);
       }
    },

    async GetStates()
    {
        try{
          const response = await AuthService.getStates('?perPage=40');

          if(response.meta.pagination.total>0&&response.data.length>0)
          {
            var States = response.data.length;
            var LocalGovernments = 0;
            var Wards = 0;

            for(var i=0; i<response.data.length; i++)
            {
                LocalGovernments += (response.data[i].localGovernments.length>0)? response.data[i].localGovernments.length : 0;
                
                for(var j=0; j<response.data[i].localGovernments.length>0; j++)
                {
                  Wards += (response.data[i].localGovernments[j].wards.length>0)? response.data[i].localGovernments[j].wards.length : 0;
                }
            }

            this.Coverage.states = States
            this.Coverage.localgovs = LocalGovernments;
            this.Coverage.wards = Wards;
          }
          else
          {
            this.Coverage.states = 0;
            this.Coverage.localgovs = 0;
            this.Coverage.wards = 0;
          }
      }
      catch(error)
      {
        if(error)
        { 
          console.log(error);
          AuthService.CheckResponseError(error);
        }
      }
    },

    async GetDashboard()
    {  
      this.isRegEnumVerLnLoading = true;

      try
      {       
        const response = await AuthService.getDashboard();

        if(response.data!==undefined)
        {
            let temp = response.data.updateSummary;
            let csum = response.data.createSummary;

            // # [ Debugger ]
            // console.log('# [ Debugger ] - Check dashboard :');
            // console.log(temp);

            this.dashboard.enumeration = parseInt(temp.businesses) + parseInt(csum.businesses_added);
            this.dashboard.updated = temp.businesses_updated;
            this.dashboard.created = csum.businesses_added;
            this.dashboard.enum = parseInt(temp.businesses_updated) + parseInt(csum.businesses_added);
            this.dashboard.stats.today = csum.today;
            this.dashboard.stats.week = csum.this_week;
            this.dashboard.stats.month = csum.this_month;

            // Mab Activity
            this.map_data = { "type" : this.map_type, "filter" : this.map_filter };
            this.map_data['by_state'] =  [{
                                              "state_id": "5db48894-5871-4907-86f7-7ca05082fde0",
                                              "name": "NIGER",
                                              "total": this.dashboard.enumeration,
                                              "updated": temp.businesses_updated,
                                              "pending": temp.businesses_pending
                                          }];
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.isRegEnumVerLnLoading = false;
      }
    },

    async GetSchemeData()
    {
      this.isSchemeLoading = true;

      try
      {
        var Scheme =  [
          { 
            name : 'TraderMoni', regs : 0, verify : 0 , enum : 0, loan : 0,
            info : '<b>Money for traders</b> - a micro-loan scheme that provides interest & collateral-free loans to petty traders & artisans in Nigeria.',
            logo : this.TraderMoniLogo
          },
          { 
            name : 'MarketMoni', regs : 0, verify : 0 , enum : 0, loan : 0,
            info : '<b>Money for the market</b> - a loan scheme that provides interest & collateral-free loans to SMEs within established market association clusters.',
            logo : this.MarketMoniLogo
          },
          { 
            name : 'FarmerMoni', regs : 0, verify : 0 , enum : 0, loan : 0,
            info : '<b>Money for farmers</b> - a loan scheme targeted at farmers belonging to aggregator farming groups.',
            logo : this.FarmerMoniLogo
          },
        ];
        
        const year = (this.byPhase!=='')? '&year='+this.byPhase : '';
        const resEnum = await AuthService.getSchemeActivity('?status=enumerated'+year);
        const resRegs = await AuthService.getSchemeActivity('?status=registered'+year);
        const resVerd = await AuthService.getSchemeActivity('?status=verified'+year);

        for(var i=0; i<Scheme.length; i++)
        {
            // Enumerated
            for(var j = 0; j<resEnum.data.enumerationActivityBySchemes.length; j++)
            {
                if(Scheme[i].name==resEnum.data.enumerationActivityBySchemes[j].scheme)
                {
                  Scheme[i].enum+=resEnum.data.enumerationActivityBySchemes[j].updated_count;
                  Scheme[i].regs+=resEnum.data.enumerationActivityBySchemes[j].updated_count;
                  Scheme[i].verify+=resEnum.data.enumerationActivityBySchemes[j].updated_count;
                }
            }

            // Registered
            for(var k = 0; k<resRegs.data.enumerationActivityBySchemes.length; k++)
            {
              if(Scheme[i].name==resRegs.data.enumerationActivityBySchemes[k].scheme)
                {
                  Scheme[i].regs+=resRegs.data.enumerationActivityBySchemes[k].pending_count;
                }
            }

            /** 
             * Verified
             * - Enumeration  ( Enumerated + Verified )
             * - Regsitration ( Registered + Verified + Enumerated )
             */
            for(var l = 0; l<resVerd.data.enumerationActivityBySchemes.length; l++)
            {
              if(Scheme[i].name==resVerd.data.enumerationActivityBySchemes[l].scheme)
              {
                Scheme[i].regs+=resVerd.data.enumerationActivityBySchemes[l].pending_count;
                Scheme[i].verify+=resVerd.data.enumerationActivityBySchemes[l].pending_count;
              }
            }
        }

        this.Scheme = Scheme;
        this.isSchemeLoading = false;
      }
      catch(error)
      {
        console.log(error);
        this.isSchemeLoading = false;
        AuthService.CheckResponseError(error);
      }
    },

    getUserLoggedIn()
    {
        return Helper.UserClass();
    },

    getToggleMapType(type)
    {
       this.map_type = type;
       
       if(this.map_type_data!=='enumeration')
       {
          this.map_filter = 'by_state';
       }

       this.GetMapActivity(this.map_type, this.map_filter, this.map_type_data);
    },

    getToggleMapTypeData(type)
    {
       // # Debug
       // console.log('# Debug - Check getToggleMapTypeData');
       // console.log({'map_type' : this.map_type, 'map_filter' : this.map_filter, 'map_type_Data' : this.map_type_data});

       if(this.map_type_data=='registration')
       {
          this.map_type = (this.byPhase!=='')? this.byPhase : ''; 
          this.map_filter = 'by_state';
       }

       if(this.map_type_data=='enumeration')
       {
          this.map_type = 'enumerated'; 
       }

       if(this.map_type_data=='loan')
       {
         this.map_type = 'disbursed'; this.map_filter = 'by_state';
       }

       this.GetMapActivity(this.map_type, this.map_filter, this.map_type_data);
    },

    isToggleMapTypeActive(type)
    {
        return (type==this.map_type)? 'bg-gradient-success' : 'bg-gradient-secondary';
    },

    mapFilterToggle(type)
    {
      this.GetMapActivity(this.map_type, this.map_filter, this.map_type_data);
    },

    mapFilterTitle(type)
    {
        var data = '';

        switch(type)
        {
          case 'by_state' : data = 'Breakdown By State'; break;
          case 'by_zone' : data = 'Breakdown By Zones'; break;
          case 'by_consultant' : data = 'Breakdown By Consultant'; break; 
        }

        return data;
    },
    
    randomArray(data)
    {
        const random = Math.floor(Math.random() * data.length);

        return random;
    },

    changePhase()
    {
        this.Registration = '--';
        this.Enumeration  = '--'; 
        this.Verification = '--';
        this.map_type = (this.map_type_data=='registration')? this.byPhase : this.map_type;

        console.log('# Debug - Check Map Data on changePhase()');
        console.log(this.map_data);

        this.RunDashboard();
    },

    disableMapTypeDate(map_type_data)
    {   
        var disabled = false;

        switch(map_type_data)
        {
          case 'enumeration' : disabled = false ;break;
          case 'registration' : disabled = true ;break;
          case 'loan' : disabled = false;break;
        }

        return disabled
    },

    /**
     * Api Sync Request 
     * 
     * @description : N/A 
     */
    async GetDashboardGeneralStats()
    {
      this.loader.isGenStats = true;

      try
      {       
        const response = await AuthService.getDashboard('/verification/general/stats');

        if(response.data!==undefined)
        {
          console.log(response.data);
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.isGenStats = false;
      }
    },

    async GetDashboardPensioners()
    {
      this.loader.pensioners = true;

      try
      {       
        const response = await AuthService.getDashboard('/verification/pensioners');

        if(response.data!==undefined)
        {
          this.stats.pensioners.failed = parseInt(response.data.failed);
          this.stats.pensioners.total = parseInt(response.data.total);
          this.stats.pensioners.verified = parseInt(response.data.verified);
          this.stats.pensioners.not_verified = parseInt(response.data.total) - parseInt(response.data.verified);
          // const map_total_data = (this.map_type=='verified')? this.stats.pensioners.verified : this.stats.pensioners.not_verified;          

          this.getStateStats();
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.pensioners = false;
      }      
    },

    async getBankStats()
    {
      this.loader.bank = true;
      
      try
      {       
        const response = await AuthService.getDashboard('/verification/bank');

        if(response.data!==undefined)
        {
          // # Debugger
          console.log('# Debugger -  check getBankStats()');
          console.log(response.data);

          const temp_total = this.handleTotal(response.data);

          this.stats.pension_amount.total = 'NGN ' + temp_total.amount.toLocaleString("en-us");
          this.stats.pension_amount.not_verified = 'NGN ' + temp_total.pending.amount.toLocaleString("en-us");
          this.stats.pension_amount.verified = 'NGN '+ (temp_total.amount - temp_total.pending.amount).toLocaleString("en-us");
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.bank = false;
      }      
    },

    async getStateStats()
    {
      this.loader.state = true;
      
      try
      {       
        const response = await AuthService.getDashboard('/verification/mda/amount');

        if(response.data!==undefined)
        {
          // # Debugger
          console.log('# Debugger -  check getStateStats()');
          console.log(response.data);

          const temp_total = this.handleTotal(response.data);

          this.stats.state.total = temp_total.count.toLocaleString("en-us");
          this.stats.state.not_verified = temp_total.pending.count.toLocaleString("en-us");
          this.stats.state.verified = (temp_total.count - temp_total.pending.count).toLocaleString("en-us");
          this.stats.state.amount = temp_total.amount;
          this.stats.state.amount_pending = temp_total.pending.amount;

          this.getLgaStats();
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.state = false;
      }      
    },

    async getLgaStats()
    {
      this.loader.lga = true;
      
      try
      {       
        const response = await AuthService.getDashboard('/verification/lga/amount');

        if(response.data!==undefined)
        {
          console.log('# Debugger -  check getStateStats()');
          console.log(response.data);
          const temp_total = this.handleTotal(response.data);

          this.stats.lga.total = temp_total.count.toLocaleString("en-us");
          this.stats.lga.not_verified = temp_total.pending.count.toLocaleString("en-us");
          this.stats.lga.verified = (temp_total.count - temp_total.pending.count).toLocaleString("en-us");
          this.stats.lga.amount = temp_total.amount;
          this.stats.lga.amount_pending = temp_total.pending.amount;

          this.getMapNewActivity();
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.lga = false;
      }      
    },

    async getPensionAmount()
    {
      this.loader.amount = true;

      try
      {       
        const response = await AuthService.getDashboard('/pension/amount');

        if(response.data!==undefined)
        {
          const failed = (response.data.failed!==null)? parseInt(response.data.failed) : 0;
          this.stats.pension_amount_cloud.verified = parseInt(response.data.verified);
          this.stats.pension_amount_cloud.not_verified = parseInt(response.data.pending) + failed;
          this.stats.pension_amount_cloud.total = parseInt(response.data.total);
          //const map_total_data = (this.map_type=='verified')? this.stats.pension_amount_cloud.verified : this.stats.pension_amount_cloud.not_verified;

          this.getMapNewActivity();
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.loader.amount = false;
      }  
    },

    /**
     * New general methods
     * 
     * @description : N/A 
     */
     handleTotal(data = [])
     {
        let temp_amount = 0;
        let temp_count  = 0;
        let pending_amount = 0;
        let pending_count = 0;

        if(data!==null&data.length>0){
            data.forEach((item, index)=>{
                temp_amount += (item.pension_amount!==null)? parseInt(item.pension_amount) : 0;
                temp_count += parseInt(item.pensioners_count);
                pending_amount += (item.pending_pension_amount!==null)? parseInt(item.pending_pension_amount) : 0;
                pending_count += (item.pending_pensioners_count!==null)? parseInt(item.pending_pensioners_count) : 0;
            });
        }

        return { 
            amount : temp_amount,  
            count : temp_count, 
            pending : { 
              count : pending_count,
              amount : pending_amount
            }
        };
     },

     getMapNewActivity()
     {    
        let dataTemp = {
          total : 0,
          state : 0,
          lga : 0,
        };

        if(this.map_type=='verified')
        {
          dataTemp.total = (this.map_filter=='population')? this.stats.pensioners.verified : this.stats.pension_amount_cloud.verified;
          dataTemp.state = (this.map_filter=='population')? this.stats.state.verified : this.stats.state.amount;
          dataTemp.lga   = (this.map_filter=='population')? this.stats.lga.verified : this.stats.lga.amount;
        }
        else if(this.map_type=='not-verified')
        {

          dataTemp.total = (this.map_filter=='population')? this.stats.pensioners.not_verified : this.stats.pension_amount_cloud.not_verified;
          dataTemp.state = (this.map_filter=='population')? this.stats.state.not_verified : this.stats.state.amount_pending;
          dataTemp.lga   = (this.map_filter=='population')? this.stats.lga.not_verified : this.stats.lga.amount_pending;
        }

        const data = {by_states :[{
          name : 'KATSINA',
          total : dataTemp,
          title : '',
          type : this.map_type
        }]};
        
        this.map_data = data;

        console.log('# Debugger - check [ map_data ] :');
        console.log(this.map_data);
     },

     mapActive(loader)
     {
        let loading = false;

        if(loader.amount||loader.pensioners||loader.state||loader.lga)
        {
          loading = true;
        }

        // console.log('# Debugger - check mapActive() :', loading);
        // console.log('# Debugger - check [ amount ] :', loader.amount);
        // console.log('# Debugger - check [ pensioners ] :', loader.pensioners);

        return loading;
     },

     handleMapChange(type = null)
     {
        if(type=='map_type')
        {
          if(this.map_filter=='amount')
          {
            this.getPensionAmount();
          }else
          {
            this.GetDashboardPensioners();
          }
        }
        else if(type=='map_filter')
        {
          this.getPensionAmount();
        }
     }
  }
};
</script>