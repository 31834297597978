<template>
    <div class="lint-one-element">
        <loader v-if="isDocLoading" style="z-index:10000" object="#344767" color1="#ffffff" color2="#2152ff" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
        <vsud-alert
            v-if="isSubmitted"
            icon="fa fa-bell"
            :dismissible="true"
            :color="alertColor">
            {{ alertMessage }}
        </vsud-alert>
        <div class="py-4 container-fluid">
            <div class="row">
            <div class="col-12">
                <div class="card mb-4">
                    <div class="card-header pb-0">
                        <div class="row">
                        <div class="col-md-8 d-flex align-items-center">
                            <h6>Pensioner Details</h6>
                        </div>
                        <div class="col-md-4 text-end">
                            <router-link :to="{ name: 'Pensioners' }">
                                Close <i class="fas fa-times-circle text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Cancel" aria-label="Cancel"></i><span class="sr-only">Close</span>
                            </router-link>
                        </div>
                        </div>
                    </div>
                    <div class="card-body p-4">
                        <div class="users-data row justify-content-md-center" v-if="isLoading==false && message==''">
                            <div class="col-md-3 text-center">
                                <div class="mt-1" v-if="viewData?.photoLink!==undefined">
                                    <img :src="cleanImg(viewData.photoLink)" class="rounded border" style="height:250px;width:250px" />
                                </div>
                                <div class="mt-1" v-else>
                                    <img :src="imgDef" class="img-fluid rounded" />
                                    <p class="pt-2">
                                        [ No-Photo Yet ]
                                    </p>
                                </div>
                                <div class="status-vm">
                                    <p class="pt-1">
                                        <vsud-badge 
                                            :color="renderStatus(viewData.status, 'color')"
                                            variant="gradient" size="sm">
                                            {{ renderStatus(viewData.status) }}
                                        </vsud-badge>
                                    </p>
                                </div>
                            </div>
                            <!-- Personal Information -->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5">
                                        <i class="fas fa-user"></i> Personal Information
                                    </h6>
                                </div>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                        <tbody>
                                            <tr> 
                                                <td style="width:40%"><b>Firstname</b></td>
                                                <td>{{viewData.firstName}}</td>
                                            </tr>
                                            <tr> 
                                                <td><b>Lastname</b></td>
                                                <td>{{viewData.lastName}}</td>
                                            </tr>
                                            <tr> 
                                                <td><b>Phone No.</b></td>
                                                <td>{{viewData.phoneNo}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Gender</b></td>
                                                <td>{{viewData.gender}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Date of birth</b></td>
                                                <td>{{ viewData.dateOfBirth }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>LGA</b></td>
                                                <td>{{ viewData.lga }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>NIN</b></td>
                                                <td>{{ viewData.nin }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>BVN</b></td>
                                                <td>{{ viewData.bvn }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Account No.</b></td>
                                                <td>{{ viewData.accountNumber }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Bank Name</b></td>
                                                <td>{{ viewData.bankName }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Email Address</b></td>
                                                <td>{{ viewData.emailAddress }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Marital Status</b></td>
                                                <td>{{ viewData.maritalStatus }}</td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Business Information-->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5"> <i class="fas fa-home"></i> Work Information</h6>
                                </div>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                        <tbody>
                                            <tr>
                                                <td style="width:40%"><b>Ministry On Retirement</b></td>
                                                <td>{{viewData.ministryOnRetirement}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Department On Retirement</b></td>
                                                <td>{{ viewData.departmentOnRetirement }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Designation On Retirement</b></td>
                                                <td>{{ viewData.designationOnRetirement }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Date Of First Appointment</b></td>
                                                <td>{{viewData.dateOfFirstAppointment}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Retirement Date</b></td>
                                                <td>{{ viewData.retirementDate }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Grade Level</b></td>
                                                <td>{{ viewData.gradeLevel }}</td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <!-- Other Information -->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5">
                                        <i class="fas fa-book"></i> Other Info
                                    </h6>
                                </div>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                        <tbody>
                                            <tr> 
                                                <td style="width:40%"><b>Nok Firstname</b></td>
                                                <td>{{ viewData.nokFirstName }}</td>
                                            </tr>
                                            <tr> 
                                                <td style="width:40%"><b>Nok Lastname</b></td>
                                                <td>{{ viewData.nokLastName }}</td>
                                            </tr>
                                            <tr> 
                                                <td><b>Nok Phone No.</b></td>
                                                <td>{{ viewData.nokPhone }}</td> 
                                            </tr>
                                            <tr> 
                                                <td><b>Nok Address</b></td>
                                                <td>{{ viewData.nokAddress }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Nok Relationship</b></td>
                                                <td>{{ viewData.nokRelationship }}</td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Geo Location -->
                            <div class="col-md-12">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5"> <i class="fas fa-map-marker-alt"></i> Geo Location</h6>
                                </div>
                                <div class="mt-3">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                            <tbody v-if="!isLoading">
                                                <tr> 
                                                    <td style="width:40%"><b> Longitude</b></td>
                                                    <td>{{ viewData.locationCoordinates.lng }}</td>
                                                </tr>
                                                <tr> 
                                                    <td><b> Latitude</b></td>
                                                    <td>{{ viewData.locationCoordinates.lat }}</td>
                                                </tr>
                                                <tr> <td><b> Adjustments</b></td><td>...</td> </tr> 
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td><b> Coordinates</b></td>
                                                    <td>... Loading</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <!-- Geo Location -->
                            <div class="col-md-12" v-if="userClass=='admin'||userClass=='superadmin'">
                                <div class="text-uppercase">
                                    <h6 class="font-weight-bolder text-sm mt-5"> <i class="fas fa-file"></i> Profile</h6>
                                </div>
                                <div class="mt-3">
                                    <div class="row">
                                        <div class="col-md-4" v-for="doc in files" :key="doc.id">
                                           <div v-if="doc.type=='pdf-complete'" class="file-holder border rounded p-3 mb-4" @click="openDoc(doc.name)">
                                            <b>Name :</b> {{ doc.name }} <br/>
                                            <b class="small">Type :</b> {{ doc.type }}
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="spinner-holder" v-else-if="isLoading==true && message==''">
                            <vsud-spinner></vsud-spinner>
                        </div>
                        <div class="nodata-data" v-else>
                            <vsud-nodata :message="message"></vsud-nodata>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>

import VsudButton from "@/components/VsudButton.vue";
import VsudAlert from "@/components/VsudAlert.vue";
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudSpinnerSmall from "@/components/VsudSpinnerSmall.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import imgDef from "@/assets/img/team-5.png";
import VsudBadge from "@/components/VsudBadge.vue";
import Helper from '@/helpers/generalHandlers.js';
import moment from "moment";
import { GoogleMap, Marker, Polygon } from "vue3-google-map";

export default {
    name : "view-beneficiary",
    data()
    {
        return {
            id : this.$route.params.id,
            title : this.subTitle,
            imgDef,
            viewData : {},
            files : [],
            isLoading : false,
            loader : {
                page : false,
                docs : false
            },
            isSubmitted : false,
            isSubmitLoading : false,
            isDocLoading : false,
            message : '',
            alertMessage : '',
            alertColor : 'success',
            type : this.formType,
            userClass : '',
            isAdminUser : false,
            is_map_polygon : false,
            is_map_active : false,
            is_data_fm : false,
            pre_process_cords_view : {},
            center : {},
            polygon : {},
            API_KEY : process.env.VUE_APP_GOOGLE_APIKEY
        }
    },

    components : 
    {
        VsudButton,
        VsudSpinner,
        VsudSpinnerSmall,
        VsudNodata,
        VsudAlert,
        VsudBadge,
        GoogleMap, 
        Marker,
        Polygon
    },

    created()
    {
        this.getDetails();
        this.getUserClass();
        this.moment = moment;
    },

    methods : 
    {
        loaderHandler(type, action = false)
        {
            switch(type)
            {
                case 'app' : this.isLoading = action; break;
                case 'doc' : this.isDocLoading = action; break;
            }
        },

        navigate(href, newTab) 
        {
            var a = document.createElement('a');
            a.href = href;
            if (newTab) 
            {
                a.setAttribute('target', '_blank');
            }
            a.click();
        },

        async getDetails(load = 'app', doc = null)
        {
            this.loaderHandler(load, true);

            try
            {
                const response = await AuthService.getSinglePensioners(this.id);
                this.viewData = response.data;
                this.files = (this.viewData?.files!==undefined)? this.viewData.files : [];

                if(load=='doc'&&this.files.length>0)
                {
                    this.files.forEach((item)=>{
                        if(item.name==doc)
                        {
                            this.navigate(item.uri, true);
                        }
                    });
                }
            }
            catch(error)
            {
                console.log(error);
                this.message = error.message
            }
            finally
            {
                this.loaderHandler(load, false);
            }

        },

        getUserClass()
        {
            if(this.$store.getters.isLoggedIn)
            {
                this.userClass = this.$store.getters.getUser.primaryRole.name;
            }
        },
        
        cleanImg(img)
        {
            if(img&&img!==null&&img!=='')
            {
                return img;
            }
            
            return '';
        },

        renderStatus(status,type='status')
        {
            let text  = 'Completed'; 
            let color = 'success';

            if(status=='pending')
            {
                color = 'danger'; text = 'Pending'
            }

            return (type=='status')? text : color;
        },

        /**
         * Re-Dundant 
         * 
         * @description : To be removed / Optimized 
         */
        calculatePolygonArea(coords,unit) 
        {
            let area = 0;

            for (let i = 0; i < coords.length; i++) 
            {
                const [x1, y1]  = [coords[i].lat, coords[i].lng];
                
                let temp_coords = coords[(i + 1) % coords.length];
                const [x2, y2]  = [temp_coords.lat, temp_coords.lng];

                area += x1 * y2 - x2 * y1
            }
            
            var total_area = Math.abs(area) / 2;

            switch(unit)
            {
                case 'hecters' : 
                    var unit_ha = 10000;
                    total_area  = (total_area * unit_ha).toFixed(6) + ' Ha'; 
                break;

                default : total_area = total_area;
            }

            return total_area;
        },

        preProcessCords()
        {
            var is_manual_active = false;
            var cords_data = null;

            if(this.viewData.gpsLocation!==null)
            {
                cords_data = {
                    lat : this.viewData.gpsLocation[0].longitude,
                    lng : this.viewData.gpsLocation[0].latitude,
                    manual : { lat : null, lng : null}
                }
            }

            if(this.is_data_fm)
            {
                if(this.viewData.manualGps!==null)
                {
                    if(this.viewData.manualGps[0].latitude!==null&&this.viewData.manualGps[0].longitude!==null)
                    {
                        if(cords_data==null)
                        {
                            cords_data = { 
                                lat : null, 
                                lng : null, 
                                manual : { lat : null, lng : null} 
                            }
                        }

                        cords_data.manual.lat = this.viewData.manualGps[0].latitude;
                        cords_data.manual.lng = this.viewData.manualGps[0].longitude;
                        is_manual_active = true;
                    }
                }
            }

            var data = {
               gps_manual : is_manual_active,
               is_mapactive : this.is_map_active,
               is_polygon : this.is_map_polygon,
               center : this.center,
               cords : cords_data 
            }

            this.pre_process_cords_view = Helper.preProcessCoordView(data)
        },

        is_cord_polygon(data)
        {
            let centre_data  = {};
            let polygon_data = {};

            if(data!==null)
            {
                let latitude  = data.latitude;
                let longitude = data.longitude;
                // var str_limit = 20;

                if(parseFloat(latitude)&&parseFloat(longitude))
                {
                    centre_data = { lat: parseFloat(latitude), lng: parseFloat(longitude) }
                    this.is_map_active = true;
                }
            }

            return { center : centre_data, polygon : polygon_data };
        },

        openDoc(name)
        {
            this.getDetails('doc', name);
        }
    }
};
</script>
<style scoped>
    .file-holder 
    {
        cursor: pointer;
        font-size: 13px;
    }
</style>
