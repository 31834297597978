import axios from 'axios';

const url = (process.env.VUE_APP_APP_DEBUG=='true')? process.env.VUE_APP_API_URL_TEST : process.env.VUE_APP_API_URL_LIVE ;
const usersUrl = url+'/users';
const BeneficiaryUrl = url+'/businesses'; // Now Businesses { old : /beneficiaries }
const DashboardUrl = url+'/dashboard';
const AreasUrl = url+'/areas';
const PhaseUrl = url+'/phases';
const BusinessSectorUrl = url+'/business-sectors';
const ExportUrl = url+'/export/pensioners';
const Pensioners = url+'/pensioners';
const Banks = url+'/banks';
const LGAs = url+'/lgas';

/**
 * To be reviwed 
 * @description : Considering a lot of things have changed from the previous API 
 */
const schemeUrl = url+'/schemes';
const disabilityUrl = url+'/disabilities';
const cropTypeyUrl = url+'/crop-types';
const TradeTypeyUrl = url+'/trade-types';
const BusinessLocationUrl = url+'/business-locations';
const StateUrl = url+'/states';
const ConsultantUrl = url+'/consultants';
const LocalGovUrl = url+'/local-governments';
const WardsUrl = url+'/wards';
const ReportsUrl = url+'/reports/activity';
const ReportsRegUrl = url+'/reports/registration';
const ReportsLoanUrl = url+'/reports/loan';
 
export default {

/*
|--------------------------------------------------------------------------/
| Old Auth service
|--------------------------------------------------------------------------/
|
| Here is where you can register auth services for your application. These
| service are loaded by the main.js within a group which
| contains the "web" middleware group. Now create something great!
|
*/


  /**
   * Users Service
   * - Endpoint @ : /users
   * - Services @ : List, Single, Update & Add
   */
  async getUsers(page=''){
    return axios.get(usersUrl+page).then(response => response.data);
  },
  async getSingleUser(id){
    return axios.get(usersUrl +'/'+ id ).then(response => response.data);
  },
  async updateUser(id,data){
    return axios.patch(usersUrl +'/'+ id, data).then(response => response.data);
  },
  async addUser(data){
    return axios.post(usersUrl, data).then(response => response.data);
  },

  /**
   * Beneficiaries
   * - Endpoint @ : 
   */
  async getBenficiaries(page=''){
    return axios.get(BeneficiaryUrl+page).then(response => response.data);
  },
  async getSingleBenficiary(id){
    return axios.get(BeneficiaryUrl+ '/'+ id ).then(response => response.data);
  },
  async getLoanBeneficiary(param='')
  {
    return axios.get(BeneficiaryUrl+'/loans'+param).then(response => response.data);
  },

  /**
   * Dashboard Analytics
   * - Endpoint @ :
   */
  async getDashboard(param=''){
    return axios.get(DashboardUrl+param).then(response => response.data);
  },

  /**
   * Areas
   * - Endpoint @ : 
   */
  async getAreas(param=''){
    return axios.get(AreasUrl+param).then(response => response.data);
  },

  /**
   * Phases
   * - Endpoint @ : 
   */
  async getPhases(param=''){
    return axios.get(PhaseUrl+param).then(response => response.data);
  },

  /**
   * Business Sectors
   * - Endpoint @ : 
   */
  async getBusinessSector(param=''){
    return axios.get(BusinessSectorUrl+param).then(response => response.data);
  },

  /**
   * Exports
   * - Endpoint @ :
   */
  async getExportUrlEndpoint(param='')
  {
    return axios({method: 'get', url: ExportUrl + param, responseType: 'blob'}).then(response => response.data);
  },

  /**
   * Schemes
   * - Endpoint @ : /schemes
   * - Services @ : List, Single, Update & Add
   */
  async getSchemes(){
    return axios.get(schemeUrl).then(response => response.data);
  },
  async getSingleScheme(id){
    return axios.get(schemeUrl + '/'+ id ).then(response => response.data);
  },
  async updateScheme(id,data){
    return axios.patch(schemeUrl + '/'+ id, data).then(response => response.data);
  },
  async addScheme(data){
    return axios.post(schemeUrl, data).then(response => response.data);
  },

  /**
   * Disabilities 
   * - Endpoint @ : /disabilities
   * - Services @ : List, Single, Update & Add
   */
  async getDisabilities(){
    return axios.get(disabilityUrl).then(response => response.data);
  },
  async getSingleDisability(id){
    return axios.get(disabilityUrl + '/'+ id ).then(response => response.data);
  },
  async updateDisability(id,data){
    return axios.patch(disabilityUrl + '/'+ id, data).then(response => response.data);
  },
  async addDisability(data){
    return axios.post(disabilityUrl, data).then(response => response.data);
  },
  
  /**
   * Crop Types
   * - Endpoint @ : /disabilities
   * - Services @ : List, Single, Update & Add
   */
  async getCropTypes(){
    return axios.get(cropTypeyUrl).then(response => response.data);
  },
  async getSingleCropType(id){
    return axios.get(cropTypeyUrl + '/'+ id ).then(response => response.data);
  },
  async updateCropType(id,data){
    return axios.patch(cropTypeyUrl + '/'+ id, data).then(response => response.data);
  },
  async addCropType(data){
    return axios.post(cropTypeyUrl, data).then(response => response.data);
  },

  /**
   * Trade Types
   * - Endpoint @ : /disabilities
   * - Services @ : List, Single, Update & Add
   */
  getTradeTypes(){
    return axios.get(TradeTypeyUrl).then(response => response.data);
  },
  getSingleTradeType(id){
    return axios.get(TradeTypeyUrl + '/'+ id ).then(response => response.data);
  },
  updateTradeType(id,data){
    return axios.patch(TradeTypeyUrl + '/'+ id, data).then(response => response.data);
  },
  addTradeType(data){
    return axios.post(TradeTypeyUrl, data).then(response => response.data);
  },

  /**
   * BusinessLocation
   * - Endpoint @ : /business-location
   * - Services @ : List, Single, Update & Add
   */
  async getBusinessLocations(){
      return axios.get(BusinessLocationUrl).then(response => response.data);
  },
  async getSingleBusinessLocation(id){
      return axios.get(BusinessLocationUrl + '/'+ id ).then(response => response.data);
  },
  async updateBusinessLocation(id,data){
      return axios.patch(BusinessLocationUrl + '/'+ id, data).then(response => response.data);
  },
  async addBusinessLocation(data){
      return axios.post(BusinessLocationUrl, data).then(response => response.data);
  },
  
  /**
   * State
   * - Endpoint @ : /business-location
   * - Services @ : List, Single, Update & Add
   */
  async getStates(page=''){
    return axios.get(StateUrl+page).then(response => response.data);
  },
  async getSingleState(id){
    return axios.get(StateUrl + '/'+ id ).then(response => response.data);
  },
  async updateState(id,data){
    return axios.patch(StateUrl + '/'+ id, data).then(response => response.data);
  },
  async addState(data){
    return axios.post(StateUrl, data).then(response => response.data);
  },

  /**
   * Consultants
   * - Endpoint @ : /business-location
   * - Services @ : List, Single, Update & Add
   */
  async getConsultants(){
    return axios.get(ConsultantUrl).then(response => response.data);
  },
  async getSingleConsultant(id){
    return axios.get(ConsultantUrl + '/'+ id ).then(response => response.data);
  },
  async updateConsultant(id,data){
    return axios.patch(ConsultantUrl + '/'+ id, data).then(response => response.data);
  },
  async addConsultant(data){
    return axios.post(ConsultantUrl, data).then(response => response.data);
  },

  /**
   * Local Governments
   * - Endpoint @ : /business-location
   * - Services @ : List, Single, Update & Add
   */
  async getLocalGovernments(){
    return axios.get(LocalGovUrl).then(response => response.data);
  },
  async getSingleLocalGovernment(id){
    return axios.get(LocalGovUrl+ '/'+ id ).then(response => response.data);
  },
  async updateLocalGovernment(id,data){
    return axios.patch(LocalGovUrl + '/'+ id, data).then(response => response.data);
  },
  async addLocalGovernment(data){
    return axios.post(LocalGovUrl, data).then(response => response.data);
  },

 /**
  * Wards
  * - Endpoint @ : /business-location
  * - Services @ : List, Single, Update & Add
  */
  async getWards(){
    return axios.get(WardsUrl).then(response => response.data);
  },
  async getSingleWard(id){
    return axios.get(WardsUrl+ '/'+ id ).then(response => response.data);
  },
  async updateWard(id,data){
    return axios.patch(WardsUrl + '/'+ id, data).then(response => response.data);
  },
  async addWard(data){
    return axios.post(WardsUrl, data).then(response => response.data);
  },

  /**
   * Roles
   * - Endpoint @ :
   */
  async getRoles(){
    return axios.get(url + '/roles').then(response => response.data);
  },

  /**
   * Dashboard Analytics
   * - Endpoint @ :
   */
  async getEnumerationActivity(param=''){
    return axios.get(DashboardUrl+'/activities'+param).then(response => response.data);
  },
  async getSchemeActivity(param=''){
    return axios.get(DashboardUrl+'/schemes'+param).then(response => response.data);
  },
  async getStateSchemeActivity(param='')
  {
    return axios.get(DashboardUrl+'/states-schemes'+param).then(response => response.data);
  },
  async getZoneActivity(param=''){
    return axios.get(DashboardUrl+'/zones'+param).then(response => response.data);
  },
  async getZoneActivityScheme(param='')
  { 
    return axios.get(DashboardUrl+'/zones-schemes'+param).then(response => response.data);
  },
  async getLoanActivity(param='')
  {
    return axios.get(DashboardUrl+'/loan'+param).then(response => response.data);
  },

  /**
   * Enumaration Reports
   * - Endpoint @ :
   */
  getReportEnumeratorActivity(param='')
  {
    return axios.get(ReportsUrl+'/enum'+param).then(response => response.data);
  },
  getReportLgaEnumerationActivity(param='')
  {
    return axios.get(ReportsUrl+'/lga'+param).then(response => response.data);
  },
  getReportStateEnumerationActivity(param='')
  {
    return axios.get(ReportsUrl+'/state'+param).then(response => response.data);
  },
  getGeoEnumerationActivity(param='')
  {
    return axios.get(ReportsUrl+'/geo'+param).then(response => response.data);
  },
  getCountryEnumerationActivity(param='')
  {
    return axios.get(ReportsUrl+'/country'+param).then(response => response.data);
  },

  /**
   * Registration Reports
   * - Endpoint @ :
   */
   getReportRegistrationActivity(param='')
   {
      return axios.get(ReportsRegUrl+param).then(response => response.data);
   },

   /**
    * Loan Reports 
    * - Endpoint @ :
    */
   getReportLoanScheme(param='')
   {
      return axios.get(ReportsLoanUrl+'/schemes'+param).then(response => response.data);
   },
   getReportLoanZone(param='')
   {
      return axios.get(ReportsLoanUrl+'/zones'+param).then(response => response.data);
   },
   getReportLoanStates(param='')
   {
      return axios.get(ReportsLoanUrl+'/states'+param).then(response => response.data);
   },
   getReportLoanGender(param='')
   {
      return axios.get(ReportsLoanUrl+'/activity'+param).then(response => response.data);
   },

   /**
    * - : Loan reports activity
    * 
    * @endpoint :
    * @description : N/A
    */
   async getLoanReportActivityState(param)
   {
      return axios.get(ReportsUrl+'/loan/state'+param).then(response => response.data);
   },
   async getLoanReportActivityZone(param)
   {
      return axios.get(ReportsUrl+'/loan/geo'+param).then(response => response.data);
   },
   async getLoanReportActivityLocalGov(param)
   {
      return axios.get(ReportsUrl+'/loan/lga'+param).then(response => response.data);
   },
   async getLoanReportActivityCountry(param)
   {
      return axios.get(ReportsUrl+'/loan/country'+param).then(response => response.data);
   },

  /**
   * Other Reports
   * 
   * @endpoint : N/A
   */
   async getDisabilityActivity(param='')
   {
      return axios.get(ReportsUrl+'/disability'+param).then(response => response.data);
   },
   async getAgeActivity(param='')
   {
      return axios.get(ReportsUrl+'/age'+param).then(response => response.data);
   },
   async getGenderActivity(param='')
   {
      return axios.get(ReportsUrl+'/gender'+param).then(response => response.data);
   },
   async getTopBusinesses(param='')
   {
      return axios.get(ReportsUrl+'/top-businesses'+param).then(response => response.data);
   },


  /*
  |--------------------------------------------------------------------------/
  | New Auth service
  |--------------------------------------------------------------------------/
  |
  | Here is where you can register auth services for your application. These
  | service are loaded by the main.js within a group which
  | contains the "web" middleware group. Now create something great!
  |
  */

  /**
   * Token Expired
   * 
   * @description : N/A
   */
  CheckResponseError(error)
  {
      if(error.response)
      {
          var message = error.response.data.message;
          var status  = error.response.data.statusCode

          if(message =='Token has expired' && status === 401)
          {
            window.location.reload();
          }
      }
  },

  /**
   * System Login & Token
   * 
   * @endpoints : /
   * @description : login, http headers & token refresh
   */
  async login(credentials) 
  {
    return axios
      .post(url + '/auth/jwt/token', credentials)
      .then(response => response.data);
  },
  async getLoggedInUserDetails(token){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return axios.get(url + '/auth/me').then(response => response.data);
  },
  async refresToken() 
  {
    return axios.get(url + '/auth/jwt/refresh').then(response => response.data);
  },

  /**
   * Pensioners
   * 
   * @endpoints : /pensioners
   * @services : List, Edit & View
   * @description : N/A
   */
  async getPensioners(page='')
  {
    return axios.get(Pensioners+page).then(response => response.data);
  },
  async getSinglePensioners(page='')
  {
    return axios.get(Pensioners+'/'+page).then(response => response.data);
  },

  /**
   * Data analytics
   * 
   * @services : /dashboard 
   * @description : Get table and data analytics
   */
  async getDashboard(param=''){
    return axios.get(DashboardUrl+param).then(response => response.data);
  },

  /**
   * Banks
   * 
   * @endpoints : /banks
   * @description : Get lists data  
   */
  async getBanks(param=''){
    return axios.get(Banks+param).then(response => response.data);
  },

  /**
   * LGAs
   * 
   * @endpoint : /lgas
   * @description : Get lists data 
   */
  async getLgas(param=''){
    return axios.get(LGAs+param).then(response => response.data);    
  }

};