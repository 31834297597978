<template>
    <div>
        <div id="map"></div>
    </div>
</template>
<script>
    import VsudSpinner from "@/components/VsudSpinner.vue";
    import VsudNodata from "@/components/VsudNodata.vue";

    export default 
    {
        name : 'simple-maps',

        props : 
        {
            dataSet : 
            {
                type : Object,
                default : {}
            },

            dataType : 
            {
                type : String,
                default : ''
            },

            dataFilter : 
            {
                type : String,
                default : ''
            },

            mapDataType :
            {
                type : String,
                default : ''
            }
        },

        data() 
        {
            return {
                map_red: false,
                isDataEmpty : false,
                count_data : 0,
            }
        },

        components : 
        {
            VsudSpinner,
            VsudNodata
        },
        
        mounted: function()
        {
            simplemaps_countrymap.load();
            this.preProcessData();
        },

        computed: 
        {
            simplemaps_countrymap : function () {return window.simplemaps_countrymap;}
        },

        methods : 
        {
            preProcessData()
            {
                var updateData = {};
                var pre_state_specific = simplemaps_countrymap.mapdata.state_specific;
                var pre_state_activity = this.dataSet.by_states;

                if(this.mapDataType=='enumeration')
                {
                    /**
                     * By States
                     */              
                    if(this.dataFilter=='by_state')
                    {
                        var pre_scheme_activity = this.dataSet.by_schemes;

                        Object.keys(pre_state_specific).forEach( key => 
                        {
                            for(var i = 0; i<pre_state_activity.length; i++)
                            {
                                if(pre_state_activity[i].name.toUpperCase()===pre_state_specific[key].name.toUpperCase())
                                {
                                    var type_color = (this.dataType=='enumerated')? 'green' : 'red';
                                    var state = pre_state_activity[i].name.toUpperCase();
                                    var color = this.densityProcess(i,type_color);
                                    var state_updated_data = (this.dataType=='enumerated')? pre_state_activity[i].updated : pre_state_activity[i].pending;
                                    var sub_title = (this.dataType=='enumerated')? 'Enumerated' : 'NotEnumerated';
                                    
                                    // Prepare Map Table Data
                                    updateData[key] = ''+state_updated_data+'';
                                    pre_state_specific[key].description = '<table>';
                                    pre_state_specific[key].description += '<tr><td style="text-align:left"><b>'+sub_title+'</b></td><td style="text-align:left"> :'+state_updated_data+'</td></tr>';
                                    
                                    // Scheme Actvity
                                    var get_scheme_data = pre_scheme_activity[state];
                                    
                                    for(var j = 0; j<get_scheme_data.length; j++)
                                    {
                                        var scheme_count_data = (this.dataType=='enumerated')? get_scheme_data[j].updated_count : get_scheme_data[j].pending_count;
                                        pre_state_specific[key].description += '<tr><td style="text-align:left">'+get_scheme_data[j].scheme+'</td><td style="text-align:left"> :'+scheme_count_data+'</td></tr>';
                                    }

                                    pre_state_specific[key].description += '</table>';
                                    
                                    // Quick Check
                                    if(color!==null)
                                    pre_state_specific[key].color = color;  
                                }
                            }
                        }); 
                    }

                    /**
                     * By Zones
                     */
                    if(this.dataFilter=='by_zone')
                    {
                        var pre_zone_data = this.dataSet.by_zones;
                        var zone_temp_count = {};

                        for(var i = 0; i<pre_state_activity.length; i++)
                        {
                            Object.keys(pre_state_specific).forEach( key => 
                            {
                                if(pre_state_activity[i].name.toUpperCase()===pre_state_specific[key].name.toUpperCase())
                                {
                                    var state = (pre_state_activity[i].name!=='FCT')? this.capitalizeFirstLetter(pre_state_activity[i].name) : pre_state_activity[i].name;
                                    var state_zone = this.densityStateZones(state);
                                    var zone_density = this.densityProcessZones(state_zone);
                                    var zone_data = { name : '', updated : 0, pending : 0 };
                                    var sub_title = (this.dataType=='enumerated')? 'Enumerated' : 'NotEnumerated';
                                    var state_updated_data = (this.dataType=='enumerated')? pre_state_activity[i].updated : pre_state_activity[i].pending;

                                    for(var j=0; j<pre_zone_data.length; j++)
                                    {
                                        if(state_zone==pre_zone_data[j].shortname)
                                        {
                                            zone_data.name = pre_zone_data[j].name;
                                            zone_data.updated = pre_zone_data[j].updated;
                                            zone_data.pending = pre_zone_data[j].pending;
                                        }
                                    }

                                    if(!zone_temp_count[state_zone])
                                    {
                                        zone_temp_count[state_zone] = 0;
                                    }

                                    var zone_data_updated_pending = (this.dataType=='enumerated')? zone_data.updated : zone_data.pending; 

                                    updateData[key] = ''+state_updated_data+'';

                                    pre_state_specific[key].description  = '<table>';
                                    pre_state_specific[key].description +=  '<tr><td style="text-align:left"><b>'+zone_data.name+'</b></td><td style="text-align:left"> :'+zone_data_updated_pending+'</td></tr>';
                                    pre_state_specific[key].description +=  '<tr><td style="text-align:left"><b>'+sub_title+'</b></td><td style="text-align:left"> :'+state_updated_data+'</td></tr>';
                                    pre_state_specific[key].description += '</table>';

                                    if(zone_density.colors[zone_temp_count[state_zone]])
                                        pre_state_specific[key].color = zone_density.colors[zone_temp_count[state_zone]]; 

                                    zone_temp_count[state_zone] ++;
                                }
                            });
                        }
                    }

                    /** 
                     * By Consultant
                     */
                    if(this.dataFilter=='by_consultant')
                    {
                        var pre_consultant_data = this.dataSet.by_consultants;
                        var color_chat = ['red', 'green', 'blue-small', 'yellow', 'pink-small', 'orange'];
                        var conslt_temp_count = {};

                        for(var i = 0; i<pre_state_activity.length; i++)
                        {
                            Object.keys(pre_state_specific).forEach( key => 
                            {

                            if(pre_state_activity[i].name.toUpperCase()===pre_state_specific[key].name.toUpperCase())
                            {
                                    var state_found = false;
                                    var state = pre_state_activity[i].name;
                                    var capFirstState = (pre_state_activity[i].name!=='FCT')? this.capitalizeFirstLetter(pre_state_activity[i].name) : pre_state_activity[i].name;
                                    var state_zone = this.densityStateZones(capFirstState);
                                    var zone = this.zoneFullName(state_zone);
                                    var consultant = '';
                                    var sub_title = (this.dataType=='enumerated')? 'Enumerated' : 'NotEnumerated';
                                    var state_updated_data = (this.dataType=='enumerated')? pre_state_activity[i].updated : pre_state_activity[i].pending;
                                    var color = '';

                                    for(var j=0; j<pre_consultant_data.length; j++)
                                    {
                                        if(pre_consultant_data[j].states.length>0)
                                        {
                                            if(!conslt_temp_count[pre_consultant_data[j].name])
                                            {
                                                conslt_temp_count[pre_consultant_data[j].name] = {};
                                                conslt_temp_count[pre_consultant_data[j].name]['states'] = pre_consultant_data[j].states.length;
                                                conslt_temp_count[pre_consultant_data[j].name]['count'] = 0;
                                            }

                                            if(conslt_temp_count[pre_consultant_data[j].name].count<conslt_temp_count[pre_consultant_data[j].name].states)
                                            {
                                                for(var k=0; k<pre_consultant_data[j].states.length; k++)
                                                {
                                                    if(state==pre_consultant_data[j].states[k].name)
                                                    {
                                                        var temp_color = color_chat[j];
                                                        state_found = true;
                                                        color = this.densityProcess((conslt_temp_count[pre_consultant_data[j].name].count),temp_color);
                                                        consultant = pre_consultant_data[j].name;

                                                        // Update count
                                                        conslt_temp_count[pre_consultant_data[j].name].count ++;

                                                        break;
                                                    }
                                                }
                                            }
                                        }

                                        if(state_found==true)
                                        {
                                            break;
                                        }
                                    }


                                    updateData[key] = ''+state_updated_data+'';

                                    pre_state_specific[key].description  = '<table>';
                                    pre_state_specific[key].description += '<tr><td style="text-align:left"><b>Consultant</b></td><td style="text-align:left"> :'+consultant+'</td></tr>';
                                    pre_state_specific[key].description += '<tr><td style="text-align:left"><b>Zone</b></td><td style="text-align:left"> :'+zone+'</td></tr>';
                                    pre_state_specific[key].description += '<tr><td style="text-align:left"><b>'+sub_title+'</b></td><td style="text-align:left"> :'+state_updated_data+'</td></tr>';
                                    pre_state_specific[key].description += '</table>';

                                    if(color!=='')
                                        pre_state_specific[key].color = color;

                            }

                            });
                        }

                    }
                }

                if(this.mapDataType=='registration')
                {
                        pre_state_activity = this.dataSet.by_registrations;

                        Object.keys(pre_state_specific).forEach( key => 
                        {
                            for(var i = 0; i<pre_state_activity.length; i++)
                            {
                                if(pre_state_activity[i].name.toUpperCase()===pre_state_specific[key].name.toUpperCase())
                                {
                                    var type_color = 'red';
                                    var state = pre_state_activity[i].name.toUpperCase();
                                    var color = this.densityProcess(i,type_color);
                                    var state_updated_data = pre_state_activity[i].total;
                                    var sub_title = 'Total';
                                    
                                    // Prepare Map Table Data
                                    updateData[key] = ''+state_updated_data+'';
                                    pre_state_specific[key].description =  '<table>';
                                    pre_state_specific[key].description +=    '<tr><td style="text-align:left"><b>'+sub_title+'</b></td><td style="text-align:left"> :'+state_updated_data+'</td></tr>';
                                    pre_state_specific[key].description += '</table>';
                                    
                                    // Quick Check
                                    if(color!==null)
                                    pre_state_specific[key].color = color;  
                                }
                            }
                        }); 
                }

                if(this.mapDataType=='loan')
                {
                        pre_state_activity = this.dataSet.by_loans;

                        Object.keys(pre_state_specific).forEach( key => 
                        {
                            for(var i = 0; i<pre_state_activity.length; i++)
                            {
                                if(pre_state_activity[i].name.toUpperCase()===pre_state_specific[key].name.toUpperCase())
                                {
                                    var type_color = (this.dataType=='account-not-funded')? 'red' : 'green';
                                    var color = this.densityProcess(i,type_color);
                                    var state_updated_data = 0;
                                    var sub_title = '';

                                    switch(this.dataType)
                                    {
                                        case 'disbursed' : state_updated_data =  pre_state_activity[i].disbursement_count; sub_title = 'Disbursed'; ;break;
                                        case 'repaid' : state_updated_data = pre_state_activity[i].repayment_count; sub_title = 'Re-paid' ;break;
                                        case 'account-not-funded' : state_updated_data = pre_state_activity[i].account_not_funded; sub_title = 'Account-not-funded';
                                    }
                                    
                                    // Prepare Map Table Data
                                    updateData[key] = ''+state_updated_data+'';
                                    pre_state_specific[key].description =  '<table>';
                                    pre_state_specific[key].description +=    '<tr><td style="text-align:left"><b>'+sub_title+'</b></td><td style="text-align:left"> :'+state_updated_data+'</td></tr>';
                                    pre_state_specific[key].description += '</table>';
                                    
                                    // Quick Check
                                    if(color!==null)
                                    pre_state_specific[key].color = color;  
                                }
                            }
                        }); 
                }

                if(this.mapDataType=='pension')
                {
                    /**
                     * By States
                     */              
                    if(this.dataFilter=='population'||this.dataFilter=='amount')
                    {
                        Object.keys(pre_state_specific).forEach( key => 
                        {
                            for(var i = 0; i<pre_state_activity.length; i++)
                            {
                                if(pre_state_activity[i].name.toUpperCase()===pre_state_specific[key].name.toUpperCase())
                                {
                                    const type_color = (this.dataType=='verified')? 'green' : 'red';
                                    const state = pre_state_activity[i].name.toUpperCase();
                                    const color = this.densityProcess(i,type_color);
                                    const state_updated_data = pre_state_activity[i].total.total;
                                    const state_real_state = pre_state_activity[i].total.state;
                                    const state_real_lga = pre_state_activity[i].total.lga;

                                    const sub_title = (this.dataType=='verified')? 'Verified' : 'Not-Verified';
                                    const denote = (this.dataFilter=='amount')? 'NGN ' : '';
                                    
                                    // Prepare Map Table Data
                                    updateData[key] = ''+state_updated_data+'';
                                    pre_state_specific[key].description = '<table>';
                                    pre_state_specific[key].description += '<tr><td style="text-align:left"><b>'+sub_title+' Data</b></td></tr>';
                                    pre_state_specific[key].description += '</table><table>';
                                    
                                    if(this.dataFilter=='population')
                                    {
                                        pre_state_specific[key].description += '<tr><td style="text-align:left"><b>Total</b></td><td style="text-align:left"> : '+state_updated_data.toLocaleString('en-us')+'</td></tr>';
                                        pre_state_specific[key].description += '<tr><td style="text-align:left"><b>State</b></td><td style="text-align:left"> : '+state_real_state.toLocaleString('en-us')+'</td></tr>';
                                        pre_state_specific[key].description += '<tr><td style="text-align:left"><b>LGA</b></td><td style="text-align:left">   : '+state_real_lga.toLocaleString('en-us')+'</td></tr>';
                                    }
                                    else
                                    {
                                        pre_state_specific[key].description += '<tr><td style="text-align:left"><b>Total</b></td><td style="text-align:left"> : '+denote+state_updated_data.toLocaleString('en-us')+'</td></tr>';
                                        pre_state_specific[key].description += '<tr><td style="text-align:left"><b>State</b></td><td style="text-align:left"> : '+denote+state_real_state.toLocaleString('en-us')+'</td></tr>';
                                        pre_state_specific[key].description += '<tr><td style="text-align:left"><b>LGA</b></td><td style="text-align:left">   : '+denote+state_real_lga.toLocaleString('en-us')+'</td></tr>';
                                    }
                                    
                                    // // Scheme Actvity
                                    // var get_scheme_data = pre_scheme_activity[state];
                                    
                                    // for(var j = 0; j<get_scheme_data.length; j++)
                                    // {
                                    //     var scheme_count_data = (this.dataType=='enumerated')? get_scheme_data[j].updated_count : get_scheme_data[j].pending_count;
                                    //     pre_state_specific[key].description += '<tr><td style="text-align:left">'+get_scheme_data[j].scheme+'</td><td style="text-align:left"> :'+scheme_count_data+'</td></tr>';
                                    // }

                                    pre_state_specific[key].description += '</table>';
                                    
                                    // Quick Check
                                    if(color!==null)
                                    pre_state_specific[key].color = color;  
                                }
                            }
                        }); 
                    }

                }

                // Updated
                simplemaps_countrymap.mapdata.state_specific = pre_state_specific;
                simplemaps_countrymap.mapdata.data.data = updateData;
                simplemaps_countrymap.refresh();
            },

            densityProcess(density,type)
            {
                var color = [];

                switch(type)
                {
                  case 'red' : 
                    color = [
                                '#a30000','#ac0000','#b60000','#c00000','#c90000',
                                '#d30000','#dd0000','#e60000','#f00000','#f90000',
                                '#ff0404','#ff0e0e','#ff1717','#ff2121','#ff2b2b',
                                '#ff3434','#ff3e3e','#ff4848','#ff5151','#ff5b5b',
                                '#ff6565','#ff6e6e','#ff7878','#ff8282','#ff8b8b',
                                '#ff9595','#ff9f9f','#ffa8a8','#ffb2b2','#ffbbbb',
                                '#ff9595','#ff9f9f','#ffa8a8','#ffb2b2','#ffbbbb',
                                '#ffb2b2','#ffbbbb',
                            ];
                  break;

                  case 'green' : 
                    color = [
                                "#005e1a","#005e1a","#005e1a","#005e1a","#005e1a",
                                "#005e1a","#005e1a","#005e1a","#009128","#009128",
                                "#009128","#009128","#009128","#009128","#009128",
                                "#00c436","#00c436","#00c436","#00c436","#00c436",
                                "#00c436","#00c436","#00f744","#00f744","#00f744",
                                "#00f744","#00f744","#00f744","#00f744","#2bff65",
                                "#2bff65","#2bff65","#2bff65","#2bff65","#2bff65",
                                "#2bff65","#2bff65"
                            ];
                  break;

                  case 'blue' :
                    color = [
                                "#001499","#001499","#001499","#001499","#001499",
                                "#001499","#001499","#001499","#001bcc","#001bcc",
                                "#001bcc","#001bcc","#001bcc","#001bcc","#001bcc",
                                "#0022ff","#0022ff","#0022ff","#0022ff","#0022ff",
                                "#0022ff","#0022ff","#334eff","#334eff","#334eff",
                                "#334eff","#334eff","#334eff","#334eff","#667aff",
                                "#667aff","#667aff","#667aff","#667aff","#667aff",
                                "#667aff", "#667aff"
                            ];
                  break;

                  case 'blue-small' :

                    color = [
                                "#020986","#030cad","#040fd4","#0411fa","#1723fb",
                                "#2b36fb","#3e48fc","#515afc","#787ffd","#9ea3fd"
                            ];

                  break;

                  case 'pink' :
                    color = [
                                "#990090","#990090","#990090","#990090","#990090",
                                "#990090","#990090","#cc00c0","#cc00c0","#cc00c0",
                                "#cc00c0","#cc00c0","#cc00c0","#cc00c0","#ff00f0",
                                "#ff00f0","#ff00f0","#ff00f0","#ff00f0","#ff00f0",
                                "#ff00f0","#ff33f3","#ff33f3","#ff33f3","#ff33f3",
                                "#ff33f3","#ff33f3","#ff33f3","#ff66f6","#ff66f6",
                                "#ff66f6","#ff66f6","#ff66f6", "#ff66f6","#ff66f6",
                                "#ff66f6","#ff66f6",
                            ];
                  break;

                  case 'pink-small' :
                    color = [
                                "#3f1b6e","#51238d","#632aad","#6c2ebd","#7532cd",
                                "#8b51d5","#9561d8","#a071dc","#b590e4","#cab0ec"
                            ];

                  break;

                  default :  
                  
                  color = [
                                "#997000","#997000","#997000","#997000","#997000",
                                "#997000","#997000","#997000","#cc9600","#cc9600",
                                "#cc9600","#cc9600","#cc9600","#cc9600","#cc9600",
                                "#ffbb00","#ffbb00","#ffbb00","#ffbb00","#ffbb00",
                                "#ffbb00","#ffbb00","#ffc933","#ffc933","#ffc933",
                                "#ffc933","#ffc933","#ffc933","#ffc933","#ffd666",
                                "#ffd666","#ffd666","#ffd666","#ffd666","#ffd666",
                                "#ffd666","#ffd666"
                          ];
                  break;
                }

                return  color[density] ;
            },

            densityStateZones(data)
            {
                var stateInZone = {
                   'Adamawa':'NE', 'Bauchi':'NE', 'Borno':'NE', 'Gombe':'NE', 'Taraba':'NE', 'Yobe':'NE',
                    'Jigawa':'NW', 'Kaduna':'NW', 'Kano':'NW', 'Katsina':'NW', 'Kebbi':'NW', 'Sokoto':'NW', 'Zamfara':'NW',
                    'Benue':'NC', 'Kogi':'NC', 'Kwara':'NC', 'Nasarawa':'NC', 'Niger':'NC', 'Plateau':'NC', 'FCT':'NC',
                    'Akwa Ibom':'SS', 'Bayelsa':'SS', 'Cross River':'SS', 'Delta':'SS', 'Edo':'SS', 'Rivers':'SS',
                    'Ekiti':'SW', 'Lagos':'SW', 'Ogun':'SW', 'Ondo':'SW', 'Osun':'SW', 'Oyo':'SW',
                    'Abia':'SE', 'Anambra':'SE', 'Ebonyi':'SE', 'Enugu':'SE', 'Imo':'SE'
                }

                return stateInZone[data];
            },

            zoneFullName(data)
            {
                var zone = {
                                'NE' : 'North East', 'NC' : 'North Central', 'NW':'North West', 
                                'SS' : 'South Sout', 'SW' : 'South West', 'SE' : 'South East'
                           }

                return zone[data];
            },

            densityProcessZones(zone)
            {
                var color =  {
                    'NE' : {
                        'states' : ['Adamawa','Bauchi','Borno','Gombe','Taraba','Yobe'],
                        // 'colors' : ['#418626','#4fa32f','#5ec137','#73cd50','#8ad56d','#a2de8a'], // old
                        'colors' : ['#FF5722','#ff6838','#ff794e','#ff794e','#ff9a7a','#ffab91'], 
                    },
                    'NW' : {
                        'states' : ['Jigawa','Kaduna','Kano','Katsina','Kebbi','Sokoto','Zamfara'],
                        'colors' : ['#5c33bf','#6c44cd','#7e5bd3','#9172d9','#a38ae0','#b5a1e6','#c8b9ec']
                    },
                    'NC' : {
                        'states' : ['Benue','Kogi','Kwara','Nasarawa','Niger','Plateau','FCT'],
                        // 'colors' : ['#FF5722','#ff6838','#ff794e','#ff794e','#ff9a7a','#ffab91','#ffbca7'], // old
                        'colors' : ['#418626','#4fa32f','#5ec137','#73cd50','#8ad56d','#a2de8a','#a2de8a'],
                    },
                    'SS' : {
                        'states' : ['Akwa Ibom','Bayelsa','Cross River','Delta','Edo','Rivers'],
                        'colors' : ['#02436c','#025f98','#037ac3','#0496ef','#24a9fc','#50bbfc']
                    },
                    'SW' : {
                        'states' : ['Ekiti','Lagos','Ogun','Ondo','Osun','Oyo'],
                        'colors' : ['#b10e0c','#d3100f','#ef1816','#f13937','#f35a59','#f67b7a']
                    },
                    'SE' : {
                        'states' : ['Abia','Anambra','Ebonyi','Enugu','Imo'],
                        'colors' : ['#fac217','#fbc831','#fbcf4b','#fcd664','#fcdd7e']
                    }
                }

                return color[zone];
            },

            capitalizeFirstLetter(string) 
            {
                string =  string.toLowerCase();

                const words = string.split(" ");

                for (let i = 0; i < words.length; i++) 
                {
                    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
                }
                
                return  words.join(" ");
            },

            RgbColoGen()
            {
                var chart = {
                                'red' : [],
                                'blue' : [],
                                'orange' : [],
                                'green' : []

                            }
                var r = 0;
                var g = 0;
                var b = 0;
            },

            RgbToHex(red, green, blue)
            {
                return '#' + red.toString(16) + green.toString(16) + blue.toString(16);
            }
        }
        

    }
</script>