<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-header pb-0">
                        <div class="row">
                            <div class="col-md-8 d-flex align-items-center">
                                <h6>View all</h6>
                            </div>
                            <div class="col-md-4 text-end">
                                <router-link :to="{ name: 'Dashboard' }">
                                    Dashboard <i class="fas fa-home text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Add User" aria-label="Add Group"></i><span class="sr-only">CLose</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                    <div class="table-data-holder" v-if="isLoading==false && message==''">
                        <div class="table-responsive p-0">
                        <table class="table align-items-center mb-0">
                            <thead>
                            <tr>
                                <th
                                    width="12"
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    #
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Ministry on retirement
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Verified Pensioners
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                   Verified Amount
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Not-Verified Pensioners
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Not-Verified Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data,index) in TableData"  :key="data.id">
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{index + 1}}
                                            </h6>
                                        </div> 
                                    </td>
                                    <td>                                   
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{data.ministry_on_retirement}}
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm text-success" v-if="data.pensioners_count!==null">
                                                {{parseInt(data.pensioners_count).toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>
                                                ...
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm" v-if="data.pension_amount!==null">
                                                NGN {{parseInt(data.pension_amount).toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>
                                                ...
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm text-danger" v-if="data.pending_pensioners_count!==null">
                                                {{parseInt(data.pending_pensioners_count).toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>
                                                ...
                                            </h6>
                                        </div>
                                    </td> 
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm" v-if="data.pending_pension_amount!==null">
                                                NGN {{parseInt(data.pending_pension_amount).toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>
                                                ...
                                            </h6>
                                        </div>
                                    </td>                              
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div class="d-flex p-3">
                                            <h6 class="mb-0 text-sm">
                                                TOTAL 
                                            </h6>
                                        </div>
                                    </td>
                                    <td class="align-middle">
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm" v-if="Total.verified.count>0">
                                                {{Total.verified.count.toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>...</h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm" v-if="Total.verified.amount>0">
                                                NGN {{Total.verified.amount.toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>...</h6>
                                        </div>
                                    </td>
                                    <td class="align-middle">
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm" v-if="Total.not_verified.count>0">
                                                {{Total.not_verified.count.toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>...</h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm" v-if="Total.not_verified.amount>0">
                                                NGN {{Total.not_verified.amount.toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>...</h6>
                                        </div>
                                    </td>  
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div class="spiner-holder" v-else-if="isLoading==true && message==''">
                        <vsud-spinner></vsud-spinner>
                    </div>
                    <div class="nodata-holder" v-else>
                        <vsud-nodata :message="message"></vsud-nodata>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';

export default {
  name: "reports-pension-count",
  data()
  {
    return {
      TableData : [],
      Total : {
        verified : {
            amount : 0,
            count  : 0
        },
        not_verified : {
            amount : 0,
            count : 0
        }
      },
      isLoading : false,
      message : '',
      Enumeration : '',
    }
  },

  components: 
  {
    VsudSpinner,
    VsudNodata
  },

  created()
  {
    this.getLgaOnRetirment();
  },

  methods :
  {
    /**
     * API Data Sync
     * 
     * @description : N/A 
     */
    async getLgaOnRetirment()
    {
      this.isLoading = true;

      try
      {       
        const response = await AuthService.getDashboard('/verification/lga/amount/');

        if(response.data!==undefined)
        {
          this.TableData = response.data;
          const temp_total = this.handleTotal(this.TableData, 'verified');
          const temp_pending_total = this.handleTotal(this.TableData, 'not-verified');

          this.Total.verified.amount = temp_total.amount;
          this.Total.verified.count = temp_total.count;
          this.Total.not_verified.amount = temp_pending_total.amount;
          this.Total.not_verified.count = temp_pending_total.count;
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.isLoading = false;
      }
    },
    
    /**
     * General Methods
     * 
     * @description : N/A 
     */
    handleTotal(data = [], type = 'verified')
    {
        let temp_amount = 0;
        let temp_count  = 0;

        if(data!==null&data.length>0)
        {
            if(type=='verified')
            {
                data.forEach((item, index)=>{
                    temp_amount += (item.pension_amount!==null)? parseInt(item.pension_amount) : 0;
                    temp_count  += parseInt(item.pensioners_count);
                });
            }
            else if(type=='not-verified')
            {
                data.forEach((item, index)=>{
                    temp_amount += (item.pending_pension_amount!==null)? parseInt(item.pending_pension_amount) : 0;
                    temp_count  += parseInt(item.pending_pensioners_count);
                });  
            }
        }

        return { amount : temp_amount,  count : temp_count };
    },

    getStatePercentage(amount)
    {
      return ((amount/this.Enumeration) * 100).toFixed(2);
    }
  }
};
</script>
