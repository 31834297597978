<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-4">
                    <div class="card-header pb-0">
                        <div class="row">
                            <div class="col-md-8 d-flex align-items-center">
                                <h6>View all</h6>
                            </div>
                            <div class="col-md-4 text-end">
                                <router-link :to="{ name: 'Dashboard' }">
                                    Dashboard <i class="fas fa-home text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Add User" aria-label="Add Group"></i><span class="sr-only">CLose</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                    <div class="table-data-holder" v-if="isLoading==false && message==''">
                        <div class="table-responsive p-0">
                        <table class="table align-items-center mb-0">
                            <thead>
                            <tr>
                                <th
                                    width="12"
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    #
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Bank Name
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Amount
                                </th>
                                <th
                                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                    Pensioners Count
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data,index) in TableData"  :key="data.id">
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{index + 1}}
                                            </h6>
                                        </div> 
                                    </td>
                                    <td>                                   
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm">
                                                {{data.bank_name}}
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm" v-if="data.pension_amount!==null">
                                                {{parseInt(data.pension_amount).toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>
                                                ...
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm" v-if="data.pensioners_count!==null">
                                                {{parseInt(data.pensioners_count).toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>
                                                ...
                                            </h6>
                                        </div>
                                    </td>                                
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div class="d-flex p-3">
                                            <h6 class="mb-0 text-sm">
                                                TOTAL 
                                            </h6>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm" v-if="Total.amount>0">
                                                {{Total.amount.toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>...</h6>
                                        </div>
                                    </td>
                                    <td class="align-middle">
                                        <div class="d-flex px-3 py-1">
                                            <h6 class="mb-0 text-sm" v-if="Total.count>0">
                                                {{Total.count.toLocaleString("en-US")}}
                                            </h6>
                                            <h6 class="mb-0 text-sm" v-else>...</h6>
                                        </div>
                                    </td>  
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div class="spiner-holder" v-else-if="isLoading==true && message==''">
                        <vsud-spinner></vsud-spinner>
                    </div>
                    <div class="nodata-holder" v-else>
                        <vsud-nodata :message="message"></vsud-nodata>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';

export default {
  name: "reports-pension-count",
  data()
  {
    return {
      TableData : [],
      Total : {
        amount : 0,
        count  : 0
      },
      isLoading : false,
      message : '',
      Enumeration : '',
    }
  },

  components: 
  {
    VsudSpinner,
    VsudNodata
  },

  created()
  {
    this.getBankStats();
  },

  methods :
  {
    /**
     * API Data Sync
     * 
     * @description : N/A 
     */
    async getBankStats()
    {
       this.isLoading = true;
       
       try
      {       
        const response = await AuthService.getDashboard('/verification/bank');

        if(response.data!==undefined)
        {
          // # Debugger
          console.log('# Debugger -  check getBankStats()');
          console.log(response.data);

          this.TableData = response.data;
          const temp_total = this.handleTotal(this.TableData);

          console.log(temp_total); 

          this.Total.amount =  temp_total.amount;
          this.Total.count =  temp_total.count;

          console.log(this.Total);
        }
      }
      catch(error)
      {
        console.log(error);
        AuthService.CheckResponseError(error);
      }
      finally
      {
        this.isLoading = false;
      }  
    },
    
    /**
     * General Methods
     * 
     * @description : N/A 
     */
    handleTotal(data = [])
    {
        let temp_amount = 0;
        let temp_count  = 0;

        if(data!==null&data.length>0){
            data.forEach((item, index)=>{
                temp_amount += (item.pension_amount!==null)? parseInt(item.pension_amount) : 0;
                temp_count  += parseInt(item.pensioners_count);
            });
        }

        return { amount : temp_amount,  count : temp_count };
    },

    getStatePercentage(amount)
    {
      return ((amount/this.Enumeration) * 100).toFixed(2);
    }
  }
};
</script>
