<template>
  <div class="card mb-4">
    <loader v-if="loader.table" style="z-index:10000" object="#344767" color1="#ffffff" color2="#2152ff" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
            <h6>List of Applicants</h6>
        </div>
        <div class="col-md-4 text-end">
            <router-link :to="{ name: 'Pensioners' }">
              Upload <i class="fas fa-plus-circle text-secondary text-lg" data-bs-toggle="tooltip" data-bs-placement="top" title="" aria-hidden="true" data-bs-original-title="Add User" aria-label="Add Group"></i><span class="sr-only">Add Beneficiary</span>
            </router-link>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-data-holder" v-if="loader.table==false && message==''">
        <div class="table-responsive p-0">
          <div class="filter-records-holder">
            <div class="row">
              <div class="col-md-12">
                <div class="px-4 py-4 text-xs">
                  <b>Total Records :</b> {{rows}}, <b>Per Page :</b> {{perPage}}
                </div>
              </div>
              <div class="col-md-12">
                <div class="py-3 text-xs">
                  <form @submit.prevent="SubmitSearchForm" class="px-4 pb-3">
                    <div class="row"> 
                      <div class="col-md-3">
                        <input type="text" name="nameInput" v-model="searchByname" class="form-control" placeholder="Filter by name" >
                      </div>
                      <div class="col-md-2">
                        <input type="text" name="phoneInput" v-model="searchByphone" class="form-control" placeholder="Filter phone No" >
                      </div>
                      <div class="col-md-2">
                        <select name="status" class="form-control" v-model="searchBystatus">
                          <option value="" :selected="true" style="color:#ccc">Filter by status</option>
                          <option value="completed">Verified</option>
                          <option value="pending">Not-verified</option>
                        </select>
                      </div>
                      <div class="col-md-2">
                        <select name="status" class="form-control" v-model="searchBytype">
                          <option value="" :selected="true" style="color:#ccc">Filter by type</option>
                          <option value="0">State</option>
                          <option value="1">LGA</option>
                        </select>
                      </div>
                      <div class="col-md-3">
                        <div class="row">
                          <div class="col-md-6">
                            <VsudButton class="form-control" color="info" variant="gradient"> Submit </VsudButton>
                          </div>
                          <div class="col-md-6">
                            <span class="btn mb-0 bg-gradient-secondary btn-md null null pull-right form-control" @click="SearchReset">Reset</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  GPS
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Firstname
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Lastname
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Phone No.
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  NIN
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  BVN
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Lga
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Designation
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Grade Level
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Contract type
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Monthly Pension
                </th>  
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Type
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Status
                </th>                
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Created At
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in TableData"  :key="data.id">
                <td>
                  <div class="d-flex px-3 py-1">
                    <h6 class="mb-0 text-sm">
                      <span v-if="data.locationCoordinates!==null">
                        <span v-if="checkGpsExist(data.locationCoordinates).status==true">
                          <i class="fas fas fa-map" v-if="checkGpsExist(data.locationCoordinates).is_polygon==true"></i>
                          <i class="fas fa-map-marker-alt" v-else></i>
                        </span>
                        <span v-else>
                          ...
                        </span>
                      </span>
                      <span v-else>
                        ...
                      </span>
                    </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                    <h6 class="mb-0 text-sm">
                      <span class="mb-0 text-sm" v-if="data.firstName!==null||data.firstName!==''||data.firstName!==undefined">
                        {{data.firstName}}
                      </span>
                      <span v-else>...</span>
                    </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <span class="mb-0 text-sm" v-if="data.lastName!==null&&data.lastName!==''">
                        {{data.lastName}}
                      </span>
                      <span v-else>...</span>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data.phoneNo!==null&&data.phoneNo!==''">
                          {{data.phoneNo}}
                        </span>
                        <span v-else>...</span>
                      </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <div class="mb-0 text-sm">
                        <span v-if="data?.nin!==undefined">
                          <span v-if="data.nin!==null&&data.nin!==''">
                            {{data.nin}}
                          </span>
                          <span v-else>. . .</span>
                        </span>
                        <span v-else>
                          . . .
                        </span>
                      </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data?.bvn!==undefined">
                          <span v-if="data.bvn!==null&&data.bvn!==''">
                          {{data.bvn}}
                          </span>
                          <span v-else>. . .</span>
                        </span>
                        <span v-else>
                          . . .
                        </span>
                      </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data.lga!==null&&data.lga!==undefined">
                          {{ data.lga }}
                        </span>
                        <span v-else>
                          ...
                        </span>
                      </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data?.designationOnRetirement!==undefined">
                          <span v-if="data.designationOnRetirement!==null&&data.designationOnRetirement!==''">
                            {{data.designationOnRetirement}}
                          </span>
                          <span v-else>. . .</span>
                        </span>
                        <span v-else>. . .</span>
                      </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data?.gradeLevel!==undefined">
                          <span v-if="data.gradeLevel!==null&&data.gradeLevel!==''">
                            {{data.gradeLevel}}
                          </span>
                          <span v-else>. . .</span>
                        </span>
                        <span v-else>. . .</span>
                      </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data.contractType!==null&&data.contractType!==undefined">
                          {{data.contractType}}
                        </span>
                        <span v-else>...</span>
                      </h6>
                  </div>
                </td>
                <td>
                  <div class="d-flex px-3 py-1">
                      <h6 class="mb-0 text-sm">
                        <span v-if="data.monthlyPension!==null&&data.monthlyPension!==undefined">
                          NGN {{data.monthlyPension.toLocaleString("en-us")}}
                        </span>
                        <span v-else>...</span>
                      </h6>
                  </div>
                </td>
                <td class="align-middle text-center text-sm">
                  <vsud-badge v-if="data.isLga==1" color="info" variant="gradient" size="sm">lga pensioner</vsud-badge>
                  <vsud-badge v-else color="warning" variant="gradient" size="sm">state pensioner</vsud-badge>
                </td>
                <td class="align-middle text-center text-sm">
                  <vsud-badge v-if="data.status=='completed'" color="success" variant="gradient" size="sm">verified</vsud-badge>
                  <vsud-badge v-else color="danger" variant="gradient" size="sm">not-verified</vsud-badge>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold" v-if="data.updatedAt!==null">
                    {{ moment(data.updatedAt).format("ddd MMM DD, YYYY [at] HH:mm a") }}
                  </span>
                  <span class="text-secondary text-xs font-weight-bold" v-else>...</span>
                </td>
                <td class="align-middle">
                  <router-link
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="View Pensioner"
                    target="_blank"
                    :to="{name:'View Pensioner' , params: {id:data.id}}">
                    <i class="fa fa-eye"></i> View
                  </router-link>
                  &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;
                </td>
              </tr>
            </tbody>
          </table>
          <div class="p-3">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @click="handlePageChange(currentPage)" aria-controls="my-table"></b-pagination>
          </div>
        </div>
      </div>
      <div class="spiner-holder text-center" v-else-if="loader.table==true && message==''">
        <!-- <vsud-spinner></vsud-spinner> -->
        <p class="py-5">
          Loading ...
        </p>
      </div>
      <div class="nodata-holder" v-else>
        <vsud-nodata :message="message"></vsud-nodata>
        <p class="text-center mb-5">
          <span class="btn mb-0 bg-gradient-success btn-md null null pull-right" @click="reloadPage">Reload</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import VsudSpinner from "@/components/VsudSpinner.vue";
import VsudButton from "@/components/VsudButton.vue";
import VsudNodata from "@/components/VsudNodata.vue";
import AuthService from '@/services/auth.service.js';
import VsudBadge from "@/components/VsudBadge.vue";
import VsudSpinnerSmall from "@/components/VsudSpinnerSmall.vue";
import moment from "moment";
import Helper from '@/services/auth.helpers.js';

export default {
  name: "beneficiary-table",
  data()
  {
    return {
      /**
       * Old data binds
       * 
       * @description : N/A 
       */
      isLoading : false,
      isSearchLoading : false,
      states : [],
      isStateLoading : false,
      /**
       * New Data Bind
       * 
       * @description 
       */
      TableData : [],
      loader : {
        table : false,
        search : false
      },
      searchBystatus : '',
      searchBytype : '',
      searchByphone : '',
      searchByname : '',
      page : '',
      message : '',
      perPage: 0,
      currentPage: 0,
      rows : 0,
    }
  },

  components: 
  {
    VsudSpinner,
    VsudNodata,
    VsudBadge,
    VsudButton,
    VsudSpinnerSmall,
  },

  created()
  {
    this.getPensioners();
    this.moment = moment;
  },

  methods :
  {
    /**
     * Old Methods
     * 
     * @description : Deprecated 
     */
    async GetBenficiaries(page='', name = '', phone = '', status = '')
    {  
      this.isLoading = (page || name || phone || status)?  false : true;
      this.isSearchLoading = (page || name || phone || status)? true : false;

      const nameUrl = (name!=='')? '&name='+name : '';
      const phoneUrl = (phone!=='')? '&phone_no='+phone : '';
      const statusUrl = (status!=='')? this.cleanStatus(status) : '';
      const pageUrl = (page!=='')? '&page='+page : '';
      const perPageUrl = '?perPage=100';
      const paramUrl = perPageUrl+nameUrl+phoneUrl+statusUrl+pageUrl

      console.log(paramUrl);

       try{
          const response = await AuthService.getBenficiaries(paramUrl);
          
          // # Debug
          console.log(response);

          if(response.data.length>0)
          {
            this.TableData = response.data;
            this.perPage = response.meta.pagination.perPage;
            this.currentPage = response.meta.pagination.currentPage;
            this.rows = response.meta.pagination.total;

            // # Debugger
            console.log('# [ Debugger ] - Check table data :');
            console.log(this.TableData);
          }
          else
          {
            this.message = 'No data please try again';
          }
          this.isLoading = false;
          this.isSearchLoading = false;
       }
       catch(error){
        if(error)
        { 
          this.isLoading = false;
          this.isSearchLoading = false;
          this.message  = error.message;
          console.log(error);
        }
       }
    },

    async GetStates()
    {
        this.isStateLoading = true;

        try{
            const response = await AuthService.getStates('?perPage=100');
            console.log(response);
            this.states = response.data
            this.isStateLoading = false;
        }
        catch(error){
          if(error)
          { 
            console.log(error);
          }
        }
    },

    reloadPage()
    {
      location.reload();
    },

    cleanStatus(type)
    {
        let res = null; 

        switch(type)
        {
          case "updated" : res = '&updated=true'; break;
          case "not-updated" : res = '&updated=false'; break;
          case "active" : res = '&status=active'; break;
          case "not-active" : res = '&status=inactive'; break;
        }

        return res;
    },

    renderStatus(status, updated, type='status')
    {
        let text  = 'Enumerated'; 
        let color = 'success';

        if(status=='inactive')
        {
            color = 'danger'; text = 'In-active'
        }

        if(updated===true)
        {
            text = 'Updated'; color = 'info';
        }

        return (type=='status')? text : color;
    },

    /**
     * New methods
     * 
     * @description : N/A 
     */
    checkGpsExist(data)
    {
        var gps = { status : false, is_polygon : false};

        if(data.lat!==null&&data.lng!==null)
        {
            gps.status = true;

            // # [ Debugger ]
            // console.log(data.latitude.length);
            // console.log(data.longitude.length);

            // : # Deprecated 
            // if(data.latitude.length>str_limit||data.longitude.length>str_limit)
            // {
            //     gps.is_polygon = true;
            // }
            // let str_limit = 20;
            // console.log(gps);
        }
       
        return gps;
    },

    async getPensioners()
    {
      this.loader.table = true;

      const nameUrl = (this.searchByname!=='')? '&name='+this.searchByname : '';
      const phoneUrl = (this.searchByphone!=='')? '&phone_no='+this.searchByphone : '';
      const pageUrl = (this.page!=='')? '&page='+this.page : '';
      const status = (this.status!=='')? '&status='+this.searchBystatus : '';
      const type = (this.type!=='')? '&is_lga='+this.searchBytype : '';
      const perPageUrl = '?perPage=100';
      const paramUrl = perPageUrl+nameUrl+phoneUrl+pageUrl+status+type;

       try{
          const response = await AuthService.getPensioners(paramUrl);

          if(response.data.length>0)
          {
            this.TableData = response.data;
            this.perPage = response.meta.pagination.perPage;
            this.currentPage = response.meta.pagination.currentPage;
            this.rows = response.meta.pagination.total;

            // # Debugger
            console.log('# [ Debugger ] - Check table data :');
            console.log(this.TableData);
          }
          else
          {
            this.message = 'No data please try again';
          }
          this.isLoading = false;
       }
       catch(error)
       {
          this.message  = error.message;
          console.log(error);
       }finally{
          this.loader.table = false;
       }
    },

    handlePageChange(value) 
    {
      this.page = value;
      this.getPensioners();
    },

    SubmitSearchForm()
    {
      this.getPensioners();
    },

    SearchReset()
    {
      this.searchByname = '';
      this.searchByphone = '';

      this.getPensioners();
    },

    getUserLoggedIn()
    {
        return Helper.UserClass();
    },
  }
};
</script>
