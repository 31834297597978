<template>
  <div class="lint-one-element">
    <div
      class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
      id="sidenav-collapse-main">
      <ul class="navbar-nav">
        <li class="nav-item">
          <sidenav-collapse navText="Dashboard" :to="{ name: 'Dashboard' }">
            <template #icon>
              <shop />
            </template>
          </sidenav-collapse>
        </li>
        <li class="mt-3 nav-item" v-if="userClass=='superadmin'">
          <h6
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">
            SETUP
          </h6>
        </li>
        <template v-if="userClass=='superadmin'">
            <li class="nav-item">
              <sidenav-collapse navText="States" :to="{ name: 'States' }">
                <template #icon>
                  <box3d />
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse navText="Local Governments" :to="{ name: 'Local Governments' }">
                <template #icon>
                  <settings />
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse navText="Wards" :to="{ path: 'Wards' }">
                <template #icon>
                  <wards />
                </template>
              </sidenav-collapse>
            </li>
            <li class="nav-item">
              <sidenav-collapse navText="Users" :to="{ name: 'Users' }">
                <template #icon>
                  <users />
                </template>
              </sidenav-collapse>
            </li>
        </template>
          <li class="mt-3 nav-item">
            <h6
              class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
              :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
            >
              LIST
            </h6>
          </li>
          <li class="nav-item" >
            <sidenav-collapse navText="Pensioners" :to="{ name: 'Pensioners' }">
              <template #icon>
                <beneficiaries />
              </template>
            </sidenav-collapse>
          </li>
          <li class="mt-3 nav-item">
            <h6
              class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
              :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">
              Reports
            </h6>
          </li>
          <li class="nav-item">
            <sidenav-collapse navText="Pension reports" :to="{ name: 'Pension Reports' }">
              <template #icon>
                <chart></chart>
              </template>
            </sidenav-collapse>
          </li>
        <li class="mt-3 nav-item">
          <h6
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'">
            MY ACCOUNT
          </h6>
        </li>
        <li class="nav-item">
          <sidenav-collapse navText="My Profile" :to="{ name: 'Profile' }">
            <template #icon>
              <customer-support />
            </template>
          </sidenav-collapse>
        </li>
        <li class="nav-item">
          <sidenav-collapse navText="Logout" :to="{ name: 'Login' }" @click="logout">
            <template #icon>
              <spaceship />
            </template>
          </sidenav-collapse>
        </li>
      </ul>
    </div>
    <div class="pt-3 mx-3 mt-3 sidenav-footer">
      <sidenav-card
        :class="cardBg"
        textPrimary="Need Help?"
        textSecondary="Conaact support"
        route="/"
        label="Support"
        icon="fas fa-life-ring">
      </sidenav-card>
    </div>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";
import Shop from "@/components/Icon/Shop.vue";
import Office from "@/components/Icon/Office.vue";
import CreditCard from "@/components/Icon/CreditCard.vue";
import Box3d from "@/components/Icon/Box3d.vue";
import CustomerSupport from "@/components/Icon/CustomerSupport.vue";
import Document from "@/components/Icon/Document.vue";
import Spaceship from "@/components/Icon/Switches.vue";
import Settings from "@/components/Icon/Settings.vue";
import Users from "@/components/Icon/Users.vue";
import Beneficiaries from "@/components/Icon/Beneficiaries.vue";
import Enumurators from "@/components/Icon/Enumurators.vue";
import Wards from "@/components/Icon/Wards.vue";
import Disability from "@/components/Icon/GettingStarted.vue";
import Croptype from "@/components/Icon/Basket.vue";
import Tradetype from "@/components/Icon/Temperature.vue";
import Chart from "@/components/Icon/Chart.vue";
import Analytics from "@/components/Icon/Analytics.vue";
import Book from "@/components/Icon/Book.vue";
import Landmark from "@/components/Icon/Landmark.vue";
import ChartLine from "@/components/Icon/ChartLine.vue";
import ChartPie from "@/components/Icon/ChartPie.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Geep Dashboard",
      controls: "dashboardsExamples",
      isActive: "active",
      userClass : ''
    };
  },
  components: {
    SidenavCollapse,
    SidenavCard,
    Shop,
    Office,
    CreditCard,
    Box3d,
    CustomerSupport,
    Document,
    Spaceship,
    Settings,
    Users,
    Beneficiaries,
    Enumurators,
    Wards,
    Disability,
    Croptype,
    Tradetype,
    Chart,
    Analytics,
    Book,
    Landmark,
    ChartLine,
    ChartPie
  },
  created(){
    this.getUserClass();
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    logout(){
      this.$store.dispatch('logout');
      this.$router.push('/auth/login');
      location.reload();
    },
    getUserClass()
    {
      if(this.$store.getters.isLoggedIn)
      {
        this.userClass = this.$store.getters.getUser.primaryRole.name;
      }
    }
  },
};
</script>
