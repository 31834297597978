<template>
  <div class="lint-one-element">
    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
        </div>
      </div>
    </div>
    <main class="mt-0 main-content main-content-bg">
      <section>
        <div class="page-header min-vh-75">
          <div class="container">
            <div class="row">
              <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                <div class="mt-5 card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h1 class="font-weight-bolder text-info text-gradient">
                      403
                    </h1>
                    <p class="mb-0">You are not Authorized to access this page</p>
                  </div>
                  <div class="card-body">
                      <div class="text-center">
                        <vsud-button
                          @click="continueAction"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="info"
                          full-width
                          >Close
                        </vsud-button>
                      </div>
                  </div>
                  <div class="px-1 pt-0 text-center card-footer px-lg-2">
                    <p class="mx-auto mb-3 text-sm">
                      Don't kow what to do ?
                      <router-link
                        :to="{ path: '/' }"
                        class="text-info text-gradient font-weight-bold"
                        >Contact Support</router-link
                      >
                    </p>
                  </div>
                  <div class="p-1 text-center">
                    <img
                      src="@/assets/img/logos/geomaps-01.png"
                      style="height:35px"
                      alt="waves"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                  <div
                    class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                    :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/curved-images/curved20.jpeg') +
                        ')',
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
import VsudSwitch from "@/components/VsudSwitch.vue";
import VsudButton from "@/components/VsudButton.vue";
import { mapMutations } from "vuex";
const body = document.getElementsByTagName("body")[0];

export default {
  name: "LogIn",
  data(){
    return {
      status : '',
    }
  },
  components: {
    AppFooter,
    VsudSwitch,
    VsudButton
  },
  created() {
    this. hideEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.showEveryDisplay()
  },
  methods: {
    ...mapMutations(["hideEveryDisplay", "toggleHideConfig",'showEveryDisplay']),
    continueAction() {
      this.$store.dispatch('logout');
      this.$router.push('/auth/login');
      location.reload();
    },
  },
};
</script>
